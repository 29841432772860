const ADD_EXPENSE = 'ADD_EXPENSE';
const UPDATE_EXPENSES = 'UPDATE_EXPENSES';
const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
const REMOVE_EXPENSE = 'REMOVE_EXPENSE';
const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
const ADD_CATEGORY = 'ADD_CATEGORY';
const UPDATE_GROSS_INCOMES = 'UPDATE_GROSS_INCOMES';
const ADD_GROSS_INCOME = 'ADD_GROSS_INCOME';
const REMOVE_GROSS_INCOME = 'REMOVE_GROSS_INCOME';
const UPDATE_GROSS_INCOME = 'UPDATE_GROSS_INCOME';
const UPDATE_INCOME_TIMEFRAME = 'UPDATE_INCOME_TIMEFRAME';
const UPDATE_BALANCE_TIMEFRAME = 'UPDATE_BALANCE_TIMEFRAME';
const UPDATE_EXPENSE_TIMEFRAME = 'UPDATE_EXPENSE_TIMEFRAME';
const UPDATE_BALANCE = 'UPDATE_BALANCE';
const SET_SELECTED_EXPENSE = 'SET_SELECTED_EXPENSE';
const SET_SELECTED_INCOME = 'SET_SELECTED_INCOME';
const UPDATE_COST = 'UPDATE_COST';
const SET_DEV_TOOLS = 'SET_DEV_TOOLS';
const UPDATE_INCOME_TOTAL = 'UPDATE_INCOME_TOTAL';
const UPDATE_INFLATION = 'UPDATE_INFLATION';

export default {
  ADD_EXPENSE,
  REMOVE_CATEGORY,
  ADD_CATEGORY,
  UPDATE_INCOME_TOTAL,
  UPDATE_INFLATION,
  UPDATE_EXPENSES,
  UPDATE_EXPENSE,
  REMOVE_EXPENSE,
  ADD_GROSS_INCOME,
  UPDATE_GROSS_INCOMES,
  REMOVE_GROSS_INCOME,
  UPDATE_GROSS_INCOME,
  UPDATE_INCOME_TIMEFRAME,
  UPDATE_BALANCE_TIMEFRAME,
  UPDATE_EXPENSE_TIMEFRAME,
  UPDATE_BALANCE,
  SET_SELECTED_INCOME,
  SET_SELECTED_EXPENSE,
  UPDATE_COST,
  SET_DEV_TOOLS,
};
