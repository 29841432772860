


const codesandbox = false

const initialise = () => {
  if (codesandbox === true) {
    return require('./widget/sandbox.js')
  } else {
    return require('./widget/index.js')
  }
}

initialise()
