import React, { useContext } from 'react'
import { Select } from '@common/Select'
import { getBalance, getTimeframe, getAmount } from '@utils/calculators'
import { timeframeOptions } from '@utils/constants'
import { prop, values } from 'ramda'
import {
  ControlWrapper,
  ControlContainer,
  ControlContent,
  ControlHeader,
  ControlTitle,
  ControlCustom,
} from '../ControlPanel/ControlPanel/ControlPanel.styled'

import { HeaderControl } from '../HeaderControl'

import { Context, Types } from '../../state/Provider'

import styled, { css } from 'styled-components'

const selectConfig = {
  layout: {
    marginLeft: '0px',
  },
  container: {
    height: '48px',
  },
}

const config = {
  title: 'Balance',
  variant: 'asIncome',
  iconName: 'CHEVRON',
}

const BalanceBuilder = ({ id }) => {
  const [localState, localDispatch] = useContext(Context)
  // const inflation = prop('inflation')(localState);
  const expenses = prop('expenses')(localState)
  const grossIncome = prop('grossIncome')(localState)
  const incomeTimeframe = prop('incomeTimeframe')(localState)
  const inflation = 0
  const balanceTimeframe = prop('balanceTimeframe')(localState)

  // console.log('balance time frame: ', balanceTimeframe)

  // const cost = prop('cost')(localState);
  // const enableTools = prop('enableTools')(localState);
  // const balance = prop('balance')(localState);

  const onChangeTimeframe = (data) => {
    console.log('onChangeTimeframe', { data })
    localDispatch({ type: Types.UPDATE_BALANCE_TIMEFRAME, data: data.id })
  }

  // useEffect(() => {
  //   if (
  //     !incomeTimeframe ||
  //     incomeTimeframe.length <= 0 ||
  //     !balanceTimeframe ||
  //     balanceTimeframe.length <= 0
  //   ) {
  //     return;
  //   }
  //   const balance = getBalance({
  //     income: grossIncome <= 0 ? 0 : grossIncome,
  //     incomeTimeframe,
  //     balanceTimeframe,
  //     inflation: 0,
  //     list: expenses,
  //   });

  //   localDispatch({ type: Types.UPDATE_BALANCE, data: balance });
  // }, [
  //   grossIncome,
  //   incomeTimeframe,
  //   cost,
  //   expenses,
  //   inflation,
  //   balanceTimeframe,
  // ]);

  const balance = getBalance({
    income: grossIncome,
    incomeTimeframe,
    balanceTimeframe,
    inflation: 0,
    list: expenses,
  })

  return (
    <>
      <ControlWrapper>
        <ControlContainer className={config.title} id={id}>
          <ControlCustom>
            <ControlHeader id="balance-builder-header" isIncome>
              <ControlTitle>{config.title}</ControlTitle>
              <HeaderControl
                amount={getAmount(balance)}
                variant={config.variant}
                timeframe={getTimeframe(balanceTimeframe)}
              />
            </ControlHeader>
            <ControlContent>
              <Select
                config={selectConfig}
                defaultValue={balanceTimeframe}
                callback={onChangeTimeframe}
                options={values(timeframeOptions)}
              />
            </ControlContent>
          </ControlCustom>
        </ControlContainer>
      </ControlWrapper>
    </>
  )
}

export { BalanceBuilder }
