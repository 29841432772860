import styled, { css } from 'styled-components'
import { mbFn, mtFn } from '@utils/styles'

const SelectOuterContainer = styled.div`
  background: white;
  position: absolute;
  top: calc(-100% + 11px);
  z-index: 999;
  width: 100%;
  border-radius: 4px;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.2);
`

const SelectOuter = styled.div`
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-right: 4px;
  margin-bottom: 4px;
  &::-webkit-scrollbar-track {
    /* background: #3d3c40; */
    background: white;
    border: none;
    border-radius: 4px;
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 4px;
    border: none;
    box-shadow: none;
  }
`

const SelectContainerGroup = styled.ul`
  margin: 0;
  padding: 0;
  margin-right: 4px;
  list-style: none;
  background: white;
  /* background: #282e33; */
  border-radius: 8px;
  padding: 11px 7px 13px 7px;
  box-sizing: border-box;
`

const SelectStyle = styled.div`
  width: 100%;
  ${mbFn};
  ${mtFn};
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background: white;
`

const SelectOption = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  margin-top: 8px;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  span.text {
    user-select: none;
    padding: 10px 14px;
    display: block;
    font-size: 15px;
    line-height: 21px;
    text-align: left;
    color: black;
  }
  &:focus {
    outline: none;
    border: none;
  }
  &:hover {
    background: white;
    border: 2px solid black;
    span.text {
      /* color: white; */
    }
  }
  &:nth-child(1) {
    margin-top: 0px;
  }

  ${(props) =>
    props.isActive &&
    css`
      background: black;
      border: 2px solid black;
      &:hover {
        background: black;
        border: 2px solid black;
      }
      span.text {
        color: white;
      }
    `}
`

const Chevron = styled.span`
  position: absolute;
  right: 0;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  pointer-events: inherit;
  margin: 0;
  padding: 0;
`

const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  background: transparent;
  border-radius: 4px;
  border: 2px solid transparent;
  &:focus {
    outline: none;
    border: none;
  }
  span.text {
    padding-left: 14px;
    text-align: left;
    font-size: 15px;
    line-height: 21px;
  }
  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      user-select: none;
      span.text {
        color: white;
        font-style: normal;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
      ${Chevron} {
        svg {
          path {
            stroke: #dcdcdc;
            fill: #dcdcdc;
          }
        }
      }
    `}

  ${(props) =>
    props.isActive &&
    css`
      span.text {
        user-select: none;
        font-style: normal;
      }
    `}
`

const SelectIcon = styled.span`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  svg {
    circle {
      fill: rgb(25, 178, 68);
      stroke: white;
    }
    path {
      stroke: white;
    }
    rect {
      stroke: white;
    }
  }
`

const SelectLabel = styled.div`
  color: white;
  font-weight: 300;
  font-size: 14px;
  padding-bottom: 8px;
  color: #b2b2b2;
  line-height: 20px;
`

const SelectContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  background: white;
  /* background: #1f2428; */
  min-width: 162px;
  height: 48px;
  display: block;
  width: 100%;
  border-radius: 8px;
  ${(props) =>
    props.isActive &&
    css`
      border-radius: 4px 4px 0 0;
    `}
`

export {
  Chevron,
  SelectContainerGroup,
  SelectOuter,
  SelectStyle,
  SelectOption,
  SelectedItem,
  SelectIcon,
  SelectContainer,
  SelectOuterContainer,
  SelectLabel,
}
