import { curry, propEq, map, assoc, prop, when } from 'ramda';

const alter = curry((state, index, items) =>
  map(when(propEq('index', index), assoc('active', state)), items)
);

const alterAll = curry((state, items) => map(assoc('active', state), items));

const getTrimmedString = ({ text, max }) =>
  text.length > max ? text.slice(0, max) : text;

const getPriority =
  (key) =>
  (list = []) =>
    list.sort((a, b) => (prop(key)(a) > prop(key)(b) ? 1 : -1));

export { alter, alterAll, getTrimmedString, getPriority };
