const getReverseMultiplier = (frequency) =>
  ({
    DAILY: 1,
    WEEKLY: 7,
    FORTNIGHTLY: 14,
    MONTHLY: 31,
    QUARTERLY: 91,
    YEARLY: 365,
    ONCE: 365,
  }[frequency] || 365)

const getLowestDenominator = (value, frequency) => {
  const result = value / getReverseMultiplier(frequency)
  return Number(result.toFixed(2))
}

const getValueByTimeframe = (value, timeframe) => value * getReverseMultiplier(timeframe) // user has set show me daily cost which will be 1

// Main problem here:
const getExpenseTotal = ({ value, frequency, timeframe }) => {
  const lowestDenominator = getLowestDenominator(value, frequency) // expense frequency MONTHLY === 31 ---> so take 222 / 31 = 7.16
  const output = getValueByTimeframe(lowestDenominator, timeframe)
  return output ? Number(output.toFixed(2)) : 0
}

export { getReverseMultiplier, getLowestDenominator, getValueByTimeframe, getExpenseTotal }
