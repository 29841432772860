import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@common/Icon';
import { prop } from 'ramda';

import {
  ExpanderContainer,
  ExpanderHeader,
  ExpanderControls,
  ExpanderButton,
  ExpanderLabel,
  ExpanderContent,
} from './MultiExpander.styled';

const MultiExpander = ({
  label,
  isDefaultExpanded,
  children,
  button,
  config,
}) => {
  const layoutStyle = prop('layout')(config) || {};
  const labelStyle = prop('label')(config) || {};
  const contentStyle = prop('content')(config) || {};
  const [localActive, setActiveState] = useState(false);
  const [maxHeightState, setMaxHeightState] = useState(1);
  const contentRef = useRef(null);

  useEffect(() => {
    if (localActive) {
      setMaxHeightState(contentRef.current.scrollHeight + 72);
    } else {
      setMaxHeightState(1);
    }
    // eslint-disable-next-line
  }, [localActive]);

  useEffect(() => {
    if (isDefaultExpanded) {
      setActiveState(true);
    }
    // eslint-disable-next-line
  }, [isDefaultExpanded]);

  const onLocalPress = () => {
    setActiveState(!localActive);
    if (contentRef.current) {
      setMaxHeightState(localActive ? contentRef.current.scrollHeight + 72 : 1);
    }
  };

  return (
    <ExpanderContainer style={layoutStyle}>
      <ExpanderHeader>
        {button}
        {label && <ExpanderLabel style={labelStyle}>{label}</ExpanderLabel>}

        <ExpanderControls>
          <ExpanderButton
            onClick={onLocalPress}
            isActive={localActive}
            className="COLLAPSE_MENU"
          >
            <Icon name="COLLAPSE_MENU" rotate={90} size={20} />
          </ExpanderButton>
        </ExpanderControls>
      </ExpanderHeader>
      <ExpanderContent
        ref={contentRef}
        style={contentStyle}
        maxHeightState={maxHeightState}
        className="ExpanderContent"
      >
        {children}
      </ExpanderContent>
    </ExpanderContainer>
  );
};

MultiExpander.defaultProps = {
  isComplete: false,
  label: '',
  isActive: false,
  children: <></>,
  button: null,
  config: {},
};

export { MultiExpander };
