import React, { useContext, useState, useMemo } from 'react'
import { useScrollPosition } from '@hooks/useScrollPosition'
import styled, { css } from 'styled-components'
import { HeaderControl } from '../HeaderControl'
import { prop, values } from 'ramda'
import { Context } from '../../state/Provider'
import { getAmount, getTimeframe } from '@utils/calculators'
import { media } from '@utils/styles'

const OverviewContainer = styled.div``
const OverviewStyles = css`
  background: #e6513d;
  color: white;
  line-height: 1;
  position: fixed;
  right: 0;
  left: 0;
  top: 48px;
  z-index: 102;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 48px;
  display: none;
  ${media.sm`
    display: none;
  `}
  ${(props) =>
    props.startPosition &&
    css`
      top: ${props.startPosition};
    `}
    ${(props) =>
      props.color &&
      css`
        background: ${props.color};
      `};
  ${(props) =>
    props.border &&
    css`
      border-bottom: 1px solid ${props.border};
    `};
`

const OverviewMenu = styled.div`
  visibility: hidden;
  transition: all 200ms ease-out;
  transform: translateY(-100%);
  ${OverviewStyles};
  ${(props) =>
    props.show &&
    css`
      visibility: visible;
      transition: all 200ms ease-in;
      transform: none;
      top: ${props.position ? props.position : '0'};
    `}
`

const config = {
  asIncome: {
    title: 'Income',
    variant: 'asIncome',
    iconName: 'CHEVRON',
    border: '#2c8c51',
    color: '#4fbe79',
    position: '48px',
  },
  asExpense: {
    title: 'Balance',
    variant: 'asExpense',
    iconName: 'CHEVRON',
    border: '#b20000',
    color: '#ff6767',
    position: '96px',
  },
  asBalance: {
    title: 'Balance',
    variant: 'asBalance',
    iconName: 'CHEVRON',
    border: '#2e3546',
    color: '#171c28',
    position: '144px',
  },
}
const Overview = () => {
  const [showIncome, setShowIncome] = useState(false)
  const [showExpenses, setShowExpenses] = useState(false)
  const [showBalance, setShowBalance] = useState(false)

  const [localState] = useContext(Context)
  const incomeTimeframe = prop('incomeTimeframe')(localState)
  const expenseTimeframe = prop('expenseTimeframe')(localState)
  const balanceTimeframe = prop('balanceTimeframe')(localState)

  const income = prop('grossIncome')(localState)
  const expenses = prop('cost')(localState)
  const balance = prop('balance')(localState)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const getElementIncome = document.querySelector('#income-builder-header')
      const getElementExpense = document.querySelector('#expense-builder-header')
      const getElementBalance = document.querySelector('#balance-builder-header')

      const getElementIncomeFromTop = -getElementIncome.getBoundingClientRect().top
      const getElementExpenseFromTop = getElementExpense.getBoundingClientRect().top
      const getElementBalanceFromTop = getElementBalance.getBoundingClientRect().top

      // console.log(
      //   'income element::',
      //   getElementIncomeFromTop,
      //   'expense element ::',
      //   getElementExpenseFromTop,
      //   'balance element',
      //   getElementBalanceFromTop
      // );

      const isShowIncome = currPos.y <= getElementIncomeFromTop
      // const isShowExpenses = currPos.y <= getElementExpenseFromTop;
      // console.log(isShowIncome);

      setShowIncome(isShowIncome)
      // setShowExpenses(isShowExpenses);
    },
    [showIncome, showExpenses, showBalance]
  )

  return useMemo(
    () => (
      <OverviewContainer>
        <OverviewMenu
          show={showIncome}
          color={config['asIncome'].color}
          border={config['asIncome'].border}
          position={config['asIncome'].position}
        >
          <HeaderControl
            amount={getAmount(income)}
            variant={config['asIncome'].variant}
            title="Income"
            isNegative={config.variant === 'asIncome' && grossIncome < 0}
            timeframe={getTimeframe(incomeTimeframe)}
          />
        </OverviewMenu>
        <OverviewMenu
          show={showExpenses}
          color={config['asExpense'].color}
          border={config['asExpense'].border}
          position={config['asExpense'].position}
        >
          <HeaderControl
            amount={getAmount(expenses)}
            variant={config['asExpense'].variant}
            title="Expenses"
            isNegative={config.variant === 'asExpense' && expenses > 0}
            timeframe={getTimeframe(expenseTimeframe)}
          />
        </OverviewMenu>
        <OverviewMenu
          show={showBalance}
          color={config['asBalance'].color}
          border={config['asBalance'].border}
          position={config['asBalance'].position}
        >
          <HeaderControl
            amount={getAmount(balance)}
            variant={config['asBalance'].variant}
            title="Balance"
            isNegative={config.variant === 'asBalance' && balance < 0}
            timeframe={getTimeframe(balanceTimeframe)}
          />
        </OverviewMenu>
      </OverviewContainer>
    ),
    [showIncome]
  )
}

export { Overview }
