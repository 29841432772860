import styled, { css } from 'styled-components'
import { media } from '@utils/styles'
import { mtFn, mbFn } from '@utils/styles'
// rgb(178, 0, 0) - red
const config = {
  // header: '#4a5266',
  // header: '#45434a',
  headerIncome: 'rgb(44, 140, 81)',
  headerExpense: 'rgb(201 52 52)',
  // content: '#F8F8F8',
  title: '#F8F8F8',
  content: '#fff',
  // content: '#45434a',
  // content: 'rgb(31, 36, 43)',
}

const ControlWrapper = styled.div.attrs({ className: 'ControlWrapper' })`
  width: 100%;
  position: relative;
  /* min-height: 400px; */
  height: 100%;
  /* box-shadow: 0px 2px 4px rgb(28 17 44 / 4%), 0px 5px 12px rgb(28 17 44 / 6%); */
  border-radius: 8px;
  ${(props) =>
    props.isActive &&
    css`
      transition: all 0.3s ease-in-out;
      min-height: 610px;
      ${(props) =>
        props.height &&
        css`
          min-height: ${props.height}px;
        `}
      height: 100%;
    `}
`

const ControlContainer = styled.div.attrs({ className: 'ControlContainer' })`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  /* background: #f4f5f8; */
`

const ControlContent = styled.div.attrs({ className: 'ControlContent' })`
  padding: 16px;
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  background: ${config.content};
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
  border-radius: 0px 0px 8px 8px;
  height: 100%;
  ul {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 16px;
  }
`

const ControlHeader = styled.div.attrs({ className: 'ControlHeader' })`
  padding: 16px 16px 16px;
  /* background: rgb(254 254 254); */
  background: transparent;
  ${(props) =>
    props.isExpense &&
    css`
      background: ${config.headerExpense};
    `}
  ${(props) =>
    props.isIncome &&
    css`
      background: ${config.headerIncome};
    `}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  width: 100%;
  border-radius: 8px 8px 0 0px;
`

const ControlFormGroup = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  ${mbFn};
  ${mtFn}
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
    input {
    margin-bottom: 12px;
  }
  div {
    &:first-child {
      margin: 0 !important;
    }
  }
`

const ScrollControlValue = styled.div.attrs({ className: 'ScollControlValue' })`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
  width: 100%;
  ${(props) =>
    props.variant === 'asExpense' &&
    css`
      background: #ff6767;
      border: 1px solid #b20000;
    `}
  ${(props) =>
    props.variant === 'asIncome' &&
    css`
      background: #4fbe79;
      border: 1px solid #4fbe79;
    `}
`

const ControlIconContainer = styled.div`
  margin-left: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ControlValue = styled.div.attrs({ className: 'ControlValue' })`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${(props) =>
    props.variant === 'asExpense' &&
    css`
      background: #ff6767;
      border: 1px solid #b20000;
    `}
  ${(props) =>
    props.variant === 'asIncome' &&
    css`
      background: #4fbe79;
      border: 1px solid #4fbe79;
    `}
    ${(props) =>
      props.isNegative &&
      css`
        background: #ff6767;
        border: 1px solid #b20000;
      `}
  span {
    color: white;
    font-weight: 600;
  }
`

const ControlTitle = styled.h3`
  overflow: hidden;
  font-weight: 600;
  font-size: 16px;
  color: ${config.title};
  width: 100%;
  margin: 0;
`

const ControlCustom = styled.div.attrs({ className: 'ControlCustom' })`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`

const ControlGroup = styled.div``

const ControlLayout = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`

const ControlForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  ${mtFn};
`

const ControlLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #9e9ba6;
  padding-bottom: 8px;
`

const ControlButton = styled.button.attrs({ type: 'button' })`
  border-radius: 8px;
  border: 1px solid #4fbe79;
  outline: none;
  padding-top: 12px;
  padding-bottom: 11px;
  padding-left: 11px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 300;
  min-width: 80px;
  background: #4fbe79;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  ${media.sm`
  max-width: 150px;
`}
  margin-left: 8px;
  &:first-child {
    margin-left: 0;
  }
  width: 100%;
  ${media.sm`
      width: unset;`}
  span {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 16px;
    color: color;
  }
  &:hover {
    background: #4fbe79;
    color: white;
  }

  ${(props) =>
    props.variant === 'OUTLINE' &&
    css`
      background: #e0e0e0;
      background: #f5f5f8;
      border: 1px solid grey;
      color: black;
      border: 1px solid #2e3546;
      span {
        margin: 0;
        padding: 0;
        font-weight: 300;
        font-size: 16px;
        color: black;
      }
      &:hover {
        /* background: grey; */
        background: #a3a3b6;
      }
    `}
  ${(props) =>
    props.isDisabled &&
    css`
      color: #999999;

      border: 1px solid #999999;
      background-color: #efefef;
      color: #666666;
      border-radius: 8px;
      span {
        margin: 0;
        padding: 0;
        font-weight: 300;
        font-size: 16px;
        color: #999999;
      }
      width: 100%;
      ${media.sm`
      width: unset;`}
      &:hover {
        border: 1px solid #999999;
        background-color: #efefef;
        color: #666666;
        cursor: not-allowed;
      }
    `}
`

export {
  ControlWrapper,
  ControlContainer,
  ControlContent,
  ScrollControlValue,
  ControlHeader,
  ControlGroup,
  ControlFormGroup,
  ControlTitle,
  ControlCustom,
  ControlForm,
  ControlValue,
  ControlIconContainer,
  ControlButton,
  ControlLayout,
  ControlLabel,
}
