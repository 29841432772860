import React, { useRef } from 'react';
import { Icon } from '@common/Icon';
import { useDimensions } from '@hooks/useDimensions';

import {
  MenuContainer,
  MenuContent,
  MenuCloseButton,
  MenuControl,
  MenuTitle,
  MenuButton,
  MenuOverlay,
} from './ControlMenu.styled';

const ControlMenu = ({
  title,
  height,
  children,
  callback,
  isActive,
  showMenuButton,
}) => {
  const handleCallback = (value) => callback(value);
  const elemRef = useRef();
  const { width } = useDimensions(elemRef);
  return (
    <>
      {showMenuButton && (
        <MenuButton onClick={() => handleCallback('OPEN')}>
          <Icon name="FILTER" size={24} />
        </MenuButton>
      )}
      <MenuContainer
        ref={elemRef}
        isActive={isActive}
        widthProp={isActive === false ? width : 500}
      >
        <MenuControl>
          <MenuCloseButton onClick={() => handleCallback('CLOSE')}>
            <Icon name="CLOSE" size={20} stroke="#9e9ba6" />
          </MenuCloseButton>
          {title && <MenuTitle>{title}</MenuTitle>}
        </MenuControl>
        <MenuContent height={height}>{children}</MenuContent>
      </MenuContainer>
      {isActive && <MenuOverlay onClick={() => handleCallback('CLOSE')} />}
    </>
  );
};

ControlMenu.defaultProps = {
  title: '',
  height: 0,
  callback: () => {},
  isActive: false,
  showMenuButton: false,
};

export { ControlMenu };
