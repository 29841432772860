/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = (props) => {
  const { fill = '#9592A8', stroke = 'none' } = props;
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g stroke={stroke} strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M8.84773803,3.06105091 C9.17530369,3.18994293 9.38983613,3.50199727 9.38983613,3.84797057 L9.38983613,6.15445922 C13.6781781,7.05444205 16.539764,10.1659405 17.4843988,14.9926072 C17.5616766,15.386067 17.3459907,15.7795268 16.9676755,15.9344234 C16.8592559,15.978518 16.747376,16 16.6354962,16 C16.3563734,16 16.0864777,15.8677161 15.9215415,15.6314141 C14.3863658,13.4357273 12.3506145,12.3514515 9.38983613,12.1807261 L9.38983613,14.3978949 C9.38983613,14.7438682 9.17530369,15.0559225 8.84773803,15.1848145 C8.52132577,15.3148372 8.14531731,15.235693 7.90079646,14.9846927 L2.74048394,9.7102959 C2.41983869,9.3824127 2.41983869,8.86458339 2.74048394,8.5367002 L7.90079646,3.26117272 C8.14531731,3.01130312 8.52132577,2.9321589 8.84773803,3.06105091 Z M7.65973582,5.95659868 L4.56285627,9.12349805 L7.65973582,12.2892668 L7.65973582,11.3078785 C7.65973582,10.839797 8.04727829,10.4599047 8.52478597,10.4599047 C10.997676,10.4599047 13.0115128,10.968689 14.6631819,12.0326134 C13.5801391,9.98390877 11.6862559,8.18055122 8.39791195,7.70003275 C7.97346067,7.63897864 7.65973582,7.28169902 7.65973582,6.86223466 L7.65973582,5.95659868 Z"
          id="Combined-Shape"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconPath;
