/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = (props) => {
  const { stroke = '#A9AEB9', fill = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_101_2)">
        <path
          d="M3 6.5V16.9019C3 17.5084 3.44772 17.9999 4 17.9999H16C16.5523 17.9999 17 17.5084 17 16.9019V13"
          stroke={stroke}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_101_2)">
          <path
            d="M6 5H9"
            stroke={stroke}
            strokeWidth="1"
            strokeLinecap="round"
          />
          <path
            d="M6 11H9.5M6 14H14.5"
            stroke={stroke}
            strokeWidth="1"
            strokeLinecap="round"
          />
          <path
            d="M6 8H8.5"
            stroke={stroke}
            strokeWidth="1"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M13 2L4 1.99995C3.4477 1.99995 3 2.34028 3 2.76013L3 6.56104"
          stroke={stroke}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="14.5" cy="7.5" r="3.75" stroke={stroke} strokeWidth=".9" />
        <path
          d="M14.4613 9.26995C14.1747 9.26995 13.898 9.23495 13.6313 9.16495C13.368 9.09162 13.153 8.99828 12.9863 8.88495L13.3113 8.15495C13.468 8.25495 13.648 8.33828 13.8513 8.40495C14.058 8.46828 14.263 8.49995 14.4663 8.49995C14.603 8.49995 14.713 8.48828 14.7963 8.46495C14.8797 8.43828 14.9397 8.40495 14.9763 8.36495C15.0163 8.32162 15.0363 8.27162 15.0363 8.21495C15.0363 8.13495 14.9997 8.07162 14.9263 8.02495C14.853 7.97828 14.758 7.93995 14.6413 7.90995C14.5247 7.87995 14.3947 7.84995 14.2513 7.81995C14.1113 7.78995 13.9697 7.75162 13.8263 7.70495C13.6863 7.65828 13.558 7.59828 13.4413 7.52495C13.3247 7.44828 13.2297 7.34995 13.1563 7.22995C13.083 7.10662 13.0463 6.95162 13.0463 6.76495C13.0463 6.55495 13.103 6.36495 13.2163 6.19495C13.333 6.02495 13.5063 5.88828 13.7363 5.78495C13.9663 5.68162 14.253 5.62995 14.5963 5.62995C14.8263 5.62995 15.0513 5.65662 15.2713 5.70995C15.4947 5.75995 15.693 5.83495 15.8663 5.93495L15.5613 6.66995C15.3947 6.57995 15.2297 6.51328 15.0663 6.46995C14.903 6.42328 14.7447 6.39995 14.5913 6.39995C14.4547 6.39995 14.3447 6.41495 14.2613 6.44495C14.178 6.47162 14.118 6.50828 14.0813 6.55495C14.0447 6.60162 14.0263 6.65495 14.0263 6.71495C14.0263 6.79162 14.0613 6.85328 14.1313 6.89995C14.2047 6.94328 14.2997 6.97995 14.4163 7.00995C14.5363 7.03662 14.6663 7.06495 14.8063 7.09495C14.9497 7.12495 15.0913 7.16328 15.2313 7.20995C15.3747 7.25328 15.5047 7.31328 15.6213 7.38995C15.738 7.46328 15.8313 7.56162 15.9013 7.68495C15.9747 7.80495 16.0113 7.95662 16.0113 8.13995C16.0113 8.34328 15.953 8.53162 15.8363 8.70495C15.723 8.87495 15.5513 9.01162 15.3213 9.11495C15.0947 9.21828 14.808 9.26995 14.4613 9.26995ZM14.2513 9.79995V9.08495H14.8513V9.79995H14.2513ZM14.2513 5.80995V5.09995H14.8513V5.80995H14.2513Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_101_2">
          <rect width="20" height="20" fill={fill} />
        </clipPath>
        <clipPath id="clip1_101_2">
          <rect
            width="34"
            height="34"
            fill={fill}
            transform="translate(0 -6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPath;
