import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { Context, Types } from '../../state/Provider'
import { prop, values } from 'ramda'
import { RenderCategories } from '../ExpenseBuilder/RenderCategories'

const ExportContainer = styled.div`
  width: 500px;
  position: fixed;
  left: -999px;
  min-height: 2600px;
  h2 {
    text-transform: capitalize;
    margin-bottom: 16px;
  }
`

const ExportView = () => {
  const [localState] = useContext(Context)
  const expenses = prop('expenses')(localState)
  const expenseTimeframe = prop('expenseTimeframe')(localState)
  const categories = prop('categories')(localState)
  const selectedExpense = prop('selectedExpense')(localState)

  return (
    <ExportContainer id="download-content">
      {values(expenses) && values(expenses).length > 0 && (
        <h2>{expenseTimeframe && expenseTimeframe.toLowerCase()} Expenses</h2>
      )}
      <RenderCategories
        items={values(expenses) || []}
        categories={values(categories) || []}
        timeframe={expenseTimeframe}
        activeId={selectedExpense}
        variant="asExpense"
      />
    </ExportContainer>
  )
}

export { ExportView }
