import React from 'react'
import styled, { css } from 'styled-components'
import { Text } from '@common/Text'
import { Icon } from '@common/Icon'

const PlaceholderItem = styled.div`
  box-shadow: none;
  background: white;
  color: black;
  max-width: 100%;
  width: 100%;
  margin: 0;
  border-radius: 4px;
  margin-top: 16px;
  background: white;
  padding: 8px 16px 16px 16px;
  box-shadow: inset 0px -2px 0px 0px #4f4e54;
  &:hover {
  }
  span {
    color: black;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
  }
`

const PlaceholderIconContainer = styled.div`
  margin-left: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Placeholder = ({ children, ...props }) => {
  return (
    <PlaceholderItem {...props}>
      {/* <PlaceholderIconContainer>
        <Icon name="PLUS" size={20} />
      </PlaceholderIconContainer> */}
      <Text>{children}</Text>
    </PlaceholderItem>
  )
}

export { Placeholder }
