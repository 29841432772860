import React from 'react'
import { Row, Col } from '@common/Grid'

import { CreditorContainer, CreditorList, Role, Features, Name, Image, Link } from './Creditors.styled'

const publicDir = `./assets/contributors`
const items = [
  {
    name: 'Philip Hultgren',
    id: 'philip',
    active: true,
    role: `Lead Engineer / Developer - Architect`,
    features: `From design to development - the whole thing`,
    image: `${publicDir}/philip.jpeg`,
    link: 'https://www.linkedin.com/in/philip-hultgren-68765566',
  },
  {
    name: 'Vinu Karunasinghe',
    id: 'vinu',
    active: true,
    role: `Inspiration giver`,
    features: `Overall of all features`,
    image: `${publicDir}/vinu.jpeg`,
    link: 'https://www.linkedin.com/in/vinujk',
  },
  {
    name: 'Sonja Lynch',
    id: 'sonja',
    active: true,
    role: `Meaningful Feedback Provider / Concept Driver / Thought Provider`,
    features: `Overview of all features`,
    image: `${publicDir}/sonja.jpeg`,
    link: 'https://www.linkedin.com/in/sonja-lynch-192b99145',
  },
  {
    name: 'Kari Jones',
    id: 'kari',
    active: true,
    role: `Meaningful Feedback Provider`,
    features: `Category feature`,
    image: `${publicDir}/kari.jpeg`,
    link: 'https://www.linkedin.com/in/kari-hultgren-862682111',
  },
  {
    name: 'Adam Norris',
    id: 'adam',
    active: true,
    role: `Resourceful Feedback Provider`,
    features: `Save feature`,
    image: `${publicDir}/adam.jpeg`,
    link: '',
  },
  {
    name: 'Solveig Hultgren',
    id: 'solveig',
    active: false,
    role: `Meaningful Feedback Provider`,
    features: `Inflation calculator tool`,
    image: `${publicDir}/solveig.jpeg`,
    link: '',
  },
  {
    name: 'Jitender Chhabra',
    id: 'jitender',
    active: true,
    role: `Meaningful Feedback Provider`,
    features: `Bug fixes | Header and logo considerations | Logo considerations | User flows`,
    image: `${publicDir}/jitender.jpeg`,
    link: 'https://www.linkedin.com/in/jitender-chhabra-70120a18',
  },
]

const CreditorItem = ({ name = '', role = '', features = '', link = '', image = '', active = false }) => {
  const renderFeatures = () => <Features>{features}</Features>

  return (
    <Col xs={12} sm={4} md={3} lg={3} xxl={2} mt={16}>
      <CreditorContainer>
        <CreditorList>
          <Image src={image} />
          <Name>{name}</Name>
          <Role>{role}</Role>
          {renderFeatures(features)}
          {link && link.length > 0 && (
            <Link href={link} target="_blank">
              LinkedIn
            </Link>
          )}
        </CreditorList>
      </CreditorContainer>
    </Col>
  )
}

const Creditors = ({ items = [] }) => {
  const list = items.map((item) =>
    item.active ? (
      <React.Fragment key={item.name}>
        <CreditorItem
          name={item.name}
          features={item.features}
          role={item.role}
          image={item.image}
          link={item.link}
        />
      </React.Fragment>
    ) : null
  )

  return <Row>{list}</Row>
}

Creditors.defaultProps = {
  items: items,
}

export { Creditors }
