import { keys } from 'ramda';

const getSavedStates = (state) => {
  const list = keys(state || {});
  if (!list || list.length <= 0) return [];
  return list.map((item) => ({
    id: item,
    label: item,
    value: item,
  }));
};

export { getSavedStates };
