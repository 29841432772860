import styled, { css, keyframes } from 'styled-components'

import { mbFn, mtFn, media, scrollStyles, buttonStyles } from '@utils/styles'

const Heading = styled.h3`
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
  color: white;
  margin: 0;
`

const IconContainer = styled.span.attrs({ className: 'IconContainer' })`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20px;
  height: 20px;
  margin-left: 8px;
`

const shake = keyframes`
10%, 90% {
  transform: translateY(-50%) translate3d(-1px, 0, 0);
}

20%, 80% {
  transform: translateY(-50%) translate3d(2px, 0, 0);
}

30%, 50%, 70% {
  transform: translateY(-50%) translate3d(-4px, 0, 0);
}

40%, 60% {
  transform: translateY(-50%) translate3d(4px, 0, 0);
}
`

const HeaderControls = styled.div`
  position: absolute;
  transform: translateY(-50%);
  right: 0px;
  top: 50%;
  display: flex;
  flex-direction: row;
`

const SaveButton = styled.button`
  ${buttonStyles};
`

const UploadButton = styled.button`
  ${buttonStyles};
`

const SettingsButton = styled.button`
  ${buttonStyles};
`

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 8px;
`

const ModalGroup = styled.div`
  padding-top: 16px;
`

const ModalFooter = styled.div`
  width: 100%;
  padding-top: 24px;
`

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  border: none;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  /* background: #38363a; */
  /* background: #15191c; */
  background: white;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: relative;
  height: 64px;
`

const pulseBorder = keyframes`
0% {
  transform: translateY(-50%) scale(1);
}
50% {
  transform: translateY(-50%) scale(1);
}
90% {
  transform: translateY(-50%) scale(1);
}
95% {
  transform: translateY(-50%) scale(1.2);
}
100% {
  transform: translateY(-50%) scale(1);
}
`

const Dot = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  border: 1px solid green;
  background: #b0ffb0;

  border-radius: 50%;
  animation: ${pulseBorder} 2s infinite;
  animation-direction: alternate;
`

const ControlValue = styled.div.attrs({ className: 'ControlValue' })`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  height: 48px;
  max-width: 200px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #81d29f;
`

const HeaderLogoMobile = styled.span`
  display: inline-flex;
  /* color: white; */
  font-size: 16px;
  font-weight: 600;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px;
  transition: all 0.3s ease-in-out;
  left: 0;
  color: black;
  border: 1px solid white;
  ${media.sm`
    display: none;
`};
`

const HeaderLogoDesktop = styled.div`
  display: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  left: 0;
  position: absolute;
  padding: 6px;
  transition: all 0.3s ease-in-out;
  left: 0;
  border: 1px solid white;
  transform: translateY(-50%);
  top: 50%;
  svg {
    circle {
      fill: none;
    }
  }
  align-items: center;
  cursor: pointer;
  animation-iteration-count: 2;
  animation-name: ${shake};
  animation-duration: 0.3s;
  display: none;
  animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
  animation-direction: alternate;
  &.is-reset {
    background: rgb(79, 190, 121);
  }
  span.deselect {
    user-select: none;
    color: black;
  }
  ${media.sm`
   display: flex;
  `};
  ${media.md`
    border: 1px solid white;
  `};
`

const ModalGrid = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  box-shadow: none;
  background: #45434a;
  color: #9e9ba6;
  max-width: 100%;
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #9e9ba6;
  &:last-child {
    border-bottom: none;
  }
  ${mtFn};
  ${mbFn};
`

const ExampleGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  margin-top: 16px;
`
const ExampleItem = styled.li`
  padding: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #646169;
  background: #38363a;
  cursor: pointer;
  max-width: 120px;
  min-height: 100px;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  position: relative;
  &:hover {
    transform: scale(1.1);
  }
  .IconContainer {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  span {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: white;
    margin-top: 4px;
  }
`

const Show = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) =>
    props.isHidden === 'desktop' &&
    css`
      ${media.sm`
      display: none;
    `}
    `}
`
// MODAL
const ModalScrollWrapper = styled.div`
  padding: 0 8px 8px 8px;
`

const ModalContent = styled.div`
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  height: 100%;
  margin-top: 8px;
  max-height: calc(100vh - 300px);
  ${scrollStyles}
`

export {
  Heading,
  IconContainer,
  shake,
  HeaderControls,
  SettingsButton,
  UploadButton,
  SaveButton,
  Container,
  ModalGroup,
  ModalFooter,
  HeaderContainer,
  HeaderContent,
  Dot,
  ControlValue,
  HeaderLogoMobile,
  HeaderLogoDesktop,
  ModalGrid,
  Right,
  ContentGroup,
  ExampleGroup,
  ExampleItem,
  Show,
  ModalScrollWrapper,
  ModalContent,
}
