import React, { forwardRef } from 'react';
import { prop } from 'ramda';
import PropTypes from 'prop-types';

import { Primary } from './Primary';
import { Secondary } from './Secondary';
import { Tertiary } from './Tertiary';

const Input = forwardRef(({ config, variant, ...props }, ref) => {
  const layoutStyle = prop('layout')(config);
  const hasMatch = ['SECONDARY', 'PRIMARY', 'TERTIARY'].includes(
    variant.toUpperCase()
  );
  if (!hasMatch) return <Primary style={layoutStyle} {...props} />;
  const result = {
    PRIMARY: <Primary style={layoutStyle} ref={ref} {...props} />,
    SECONDARY: <Secondary style={layoutStyle} ref={ref} {...props} />,
    TERTIARY: <Tertiary style={layoutStyle} ref={ref} {...props} />,
  }[variant ? variant.toUpperCase() : 'PRIMARY'];
  return result;
});

Input.defaultProps = {
  variant: 'primary',
};
Input.propTypes = {
  variant: PropTypes.string,
};

export { Input };
