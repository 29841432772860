import React, { useEffect, useState, useRef, useMemo, useContext } from 'react'
import { uuid } from '@utils/uuid'
import { regexRequest, alphaNumericSpace } from '@utils/regex'
import { prop, values, includes, dissoc, keys } from 'ramda'
import { Controls, ControlMenu } from '@features/ControlPanel'
import {
  ControlWrapper,
  ControlContainer,
  ControlContent,
  ControlHeader,
  ControlTitle,
  ControlCustom,
  ControlGroup,
  ControlLayout,
  ControlFormGroup,
  ControlButton,
  ControlForm,
  ControlIconContainer,
  ControlLabel,
} from '@features/ControlPanel/ControlPanel/ControlPanel.styled'
import { HeaderControl } from '@features/HeaderControl'
import { getCost, getCents, getAmount, getTimeframe, getExpenseTotal } from '@utils/calculators'
import styled from 'styled-components'
import { Icon } from '@common/Icon'
import {
  Provider as PanelProvider,
  Context as PanelContext,
  Types as PanelTypes,
} from '@features/ControlPanel/ControlProvider'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@utils/services/flame'

import { List } from '@common/List'

import { Input } from '@common/Input'
// import { Search } from '../Search'
import { Select } from '@common/Select'

import { Context, Types } from '@state/Provider'
// import { media } from '@utils/styles'

import { timeframeOptions } from '@utils/constants'
import { SelectWrapper } from './IncomeBuilder.styled'

const selectConfig = {
  layout: {
    marginLeft: '0px',
  },
  container: {
    height: '48px',
  },
}

const searchConfig = {
  input: {
    placeholder: 'Search through your incomes',
  },
}

const defaultState = {
  value: '',
  label: '',
  frequency: 'YEARLY',
}

const config = {
  title: 'Income',
  variant: 'asIncome',
  iconName: 'CHEVRON',
}

import { timeframeOptionKeys } from '@utils/constants'
import { StateView } from '@common/StateView'

const CardContainer = styled.div`
  width: 100%;
  list-style: none;
  position: relative;
  min-height: 300px;

  ul.dots-group {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #ccc;
      margin: 0 4px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      &.active {
        background: #999;
        transform: scale(1.3);
      }
      &:hover {
        background: #999;
      }
    }
  }

  ul.stacked-cards {
    margin: 0;
    padding: 0;
    list-style: none;
    /* perspective: 1000px; */
    position: absolute;
    width: calc(100% - 24px);
    top: 50%;
    transition: all 0.2s ease-in-out;
    /* transform: translate(12px, 50%); */
  }
`
const Navigate = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 100px;
  z-index: 999;
`

const CardNavigate = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: white;
  outline: none;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
`
const CardItem = styled.li`
  width: 100%;
  list-style: none;
  margin: 0;
  min-height: 120px;
  span.total {
    color: white;
    font-weight: 600;
    background: #4fbe79;
    border: 1px solid #2c8c51;
    padding: 4px 8px;
    text-align: center;
    border-radius: 3px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &.card {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.1);
    /* width: 100%;
    transform-origin: 50% 0;
    transform: perspective(10px) translateY(75px) scale(0.9) rotateX(-10deg); */
    opacity: 1;
    transition: all 0.4s ease, max-height 0.75s ease;
    /* &:nth-child(n + 1) {
      position: absolute;
      max-height: 100px;
      overflow: hidden;
    } */
  }
`

const createArray = (items, timeframeOptionKeys) => {
  if (!items || !items.length) return 0
  return keys(timeframeOptionKeys).map((key) => {
    const total = items.reduce((prev, curr) => {
      const totalGroup = getExpenseTotal({ value: curr.value, frequency: curr.frequency, timeframe: key })
      const result = prev + totalGroup
      return Number(result.toFixed(2))
    }, 0)

    return {
      timeframe: key,
      total: total,
    }
  })
}

const arrayOrder = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

const ControlPager = ({ items: initialItems = [] }) => {
  //   // create Array of objects based on total
  // take the items array and map over it construct an output for each object

  const [activeItem, setActiveItem] = useState(null)
  const [items, setItems] = useState([])

  useEffect(() => {
    if (initialItems) {
      const result = createArray(initialItems, timeframeOptionKeys)

      setItems(result)
    }
  }, [initialItems])

  const renderItems = () => {
    if (!items || !items.length) return null

    return items.map((item, index) => {
      //   if (index > 3) return null
      const { timeframe, total } = item
      // const generatePixelHeight = (index / items.slice(0, 3).length) * 40
      // const multiply = index * 8
      // const transformValue = `translate3d(0, -${generatePixelHeight}px, ${multiply}px)`
      // const opactiyDecimals = (index / items.slice(0, 3).length) * 100

      // const style = {
      //   opacity: `${opactiyDecimals}%`,
      //   zIndex: `${index}`,
      //   boxShadow: `0 0 2px 1px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.1)`,
      //   transform: transformValue,
      //   background: activeItem === item.id ? '' : '',
      // }

      return (
        <CardItem
          // style={style}
          // className={`card ${item.id === activeItem ? 'added' : ''}`}
          className="card"
          onClick={() => {
            setActiveItem(item.id)
            // setItems(arrayOrder(items, index, items.length - 1))
          }}
          key={timeframe}
        >
          <span className="total">
            {getAmount(total)} {getTimeframe(timeframe)}
          </span>
        </CardItem>
      )
    })
  }

  const renderDots = () => {
    if (!items || !items.length) return null
    return items.map((item, index) => {
      return (
        <li
          className={index === activeItem ? 'active' : ''}
          onClick={() => {
            setActiveItem(item.id)
            setItems(arrayOrder(items, index, items.length - 1))
          }}
          key={index}
        ></li>
      )
    })
  }

  const handleBack = () => {}
  const handleForward = () => {}

  return (
    <CardContainer>
      <Navigate>
        <CardNavigate onClick={handleBack}>
          <Icon name="CHEVRON" size={32} rotate={-90} />
        </CardNavigate>
        <CardNavigate onClick={handleForward}>
          <Icon name="CHEVRON" size={32} rotate={90} />
        </CardNavigate>
      </Navigate>
      <ul className="stacked-cards">{renderItems()}</ul>
      <ul className="dots-group">{renderDots()}</ul>
    </CardContainer>
  )
}

// ;<div style={{ marginTop: '200px' }}>
//   <StateView state={{ items }} />
// </div>

const IncomeBuilderFeature = ({ id }) => {
  const [localState, localDispatch] = useContext(Context)
  const [localPanelState, localPanelDispatch] = useContext(PanelContext)
  const selectedIncome = prop('selectedIncome')(localState)
  const grossIncome = prop('grossIncome')(localState)
  const incomeTimeframe = prop('incomeTimeframe')(localState)
  const incomes = prop('incomes')(localState)
  const inflation = prop('inflation')(localState)

  const items = values(incomes)
  const [state, setState] = useState(defaultState)
  const [localSearch, setLocalSearch] = useState([])
  const ref = useRef(null)
  const refLabel = useRef(null)

  const { isMenuOpen } = localPanelState

  const focusLabel = () => {
    if (refLabel.current) {
      refLabel.current.focus()
    }
  }

  useEffect(() => {
    focusLabel()
  }, [])

  const onCreate = (e) => {
    e.preventDefault()
    if (state.value === '' || state.label === '' || state.frequency === '') {
      return
    }
    const id = uuid()
    const { label, frequency, value } = state

    const coercedNumber = Number(value)

    const data = {
      id,
      value: coercedNumber,
      label,
      frequency,
    }

    localDispatch({ type: Types.ADD_GROSS_INCOME, data: data })

    setState(defaultState)
    localPanelDispatch({ type: PanelTypes.SET_MENU_CLOSED })
    focusLabel()
  }

  const onUpdate = () => {
    if (state.value === '' || state.label === '' || state.frequency === '') {
      return
    }

    const { label, frequency, value } = state
    const coercedNumber = Number(value)

    const data = {
      id: selectedIncome,
      value: coercedNumber,
      label,
      frequency,
    }

    localDispatch({ type: Types.UPDATE_GROSS_INCOME, data: data })
    localPanelDispatch({ type: PanelTypes.SET_MENU_CLOSED })
    setState(defaultState)
  }

  const onChange = (e) => {
    const { value, name } = e.target

    if (name === 'value') {
      const result = regexRequest(value)
      if (result !== '') {
        const num = Number(getCents(result).toFixed(0))
        setState((state) => ({ ...state, [name]: num }))
        return
      }
      setState((state) => ({ ...state, [name]: value }))
      return
    }
    const result = alphaNumericSpace(value)

    setState((state) => ({ ...state, [name]: result }))
  }

  const onChangeFrequency = ({ id }) => {
    setState((state) => ({ ...state, frequency: id }))
  }

  const validate = () => {
    if (!state.label || state.label.length <= 0) {
      return false
    }
    if (state.value === undefined || state.value === '') {
      return false
    }
    return true
  }

  const onEdit = (selectedIncome) => {
    if (!selectedIncome || selectedIncome.length <= 0) return
    const mapped = items.map((item) => prop('id')(item))
    const exists = includes(selectedIncome)(mapped)
    if (!exists) return
    const findBySelectedId = items.find((t) => t.id === selectedIncome)
    setState((state) => ({
      ...state,
      ...findBySelectedId,
    }))

    localDispatch({ type: Types.SET_SELECTED_INCOME, data: selectedIncome })
    localPanelDispatch({ type: PanelTypes.SET_MENU_OPEN })
  }

  const onRemove = (id) => {
    const removedById = dissoc(id, incomes)

    localDispatch({ type: Types.REMOVE_GROSS_INCOME, data: removedById })

    const combinedIncome = getCost({
      list: incomes,
      timeframe: incomeTimeframe,
      inflation: '0',
    })

    localDispatch({ type: Types.UPDATE_INCOME_TOTAL, data: combinedIncome })
  }

  const onCancel = () => {
    setState(defaultState)
    localDispatch({ type: Types.SET_SELECTED_INCOME, data: '' })
    localPanelDispatch({ type: PanelTypes.SET_MENU_CLOSED })
  }

  useEffect(() => {
    if (!incomes || values(incomes).length <= 0) {
      localDispatch({ type: Types.UPDATE_INCOME_TOTAL, data: 0 })
      setLocalSearch([])
      return
    }
    const combinedIncome = getCost({
      list: incomes,
      timeframe: incomeTimeframe,
      inflation: '0',
    })
    const roundedIncomes = combinedIncome
    localDispatch({ type: Types.UPDATE_INCOME_TOTAL, data: roundedIncomes })
    setLocalSearch(values(incomes))
  }, [incomes, incomeTimeframe])

  const handleSearchCallback = (data) => {
    const { payload } = data
    if (!payload || payload.length <= 0) {
      setLocalSearch(values(incomes))
      return
    }
    setLocalSearch(payload)
  }

  const hasNoErrors = validate()

  const handleControlCallback = (value) => {
    if (value === 'CREATE') {
      localPanelDispatch({ type: PanelTypes.SET_MENU_OPEN })
    }
  }

  const handleMenuCallback = (value) => {
    if (value === 'OPEN') {
      localPanelDispatch({ type: PanelTypes.SET_MENU_OPEN })
    } else {
      onCancel()
    }
  }

  const handleIncomeTimeframe = ({ id }) => {
    if (id) {
      localDispatch({ type: Types.UPDATE_INCOME_TIMEFRAME, data: id })
    }
  }

  const onHandleListCallback = (value) => {
    if (value === 'placeholder') {
      localPanelDispatch({ type: PanelTypes.SET_MENU_OPEN })
    }
  }

  const onFocusIncome = () => {
    logEvent(analytics, 'add_balance')
  }

  return (
    <ControlWrapper isActive={isMenuOpen} height={610} id={id}>
      <ControlContainer className={config.title}>
        <ControlCustom>
          <ControlHeader id="income-builder-header">
            {/* <ControlTitle>{config.title}</ControlTitle> */}

            {/* <HeaderControl
              amount={getAmount(grossIncome)}
              variant={config.variant}
              isNegative={grossIncome < 0}
              timeframe={getTimeframe(incomeTimeframe)}
            /> */}

            {/* <Controls callback={handleControlCallback} /> */}
            <ControlPager items={items} />
          </ControlHeader>
          {isMenuOpen && (
            <ControlMenu title={'Additional Income'} isActive callback={handleMenuCallback}>
              <ControlLayout>
                <ControlFormGroup>
                  <ControlForm>
                    <ControlLabel htmlFor="value">Income Name</ControlLabel>
                    <Input
                      ref={refLabel}
                      variant="TERTIARY"
                      type="text"
                      name="label"
                      placeholder="ie: Work Salary"
                      mb={12}
                      onFocus={onFocusIncome}
                      value={state.label}
                      onChange={onChange}
                    />
                  </ControlForm>
                  <ControlForm>
                    <ControlLabel htmlFor="value">Amount</ControlLabel>
                    <Input
                      ref={ref}
                      variant="TERTIARY"
                      type="number"
                      name="value"
                      value={state.value}
                      onChange={onChange}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/g.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                    />
                  </ControlForm>
                  <ControlForm>
                    <ControlLabel htmlFor="value">Occurrence</ControlLabel>
                    <Select
                      config={selectConfig}
                      variant={config.variant}
                      defaultValue={state.frequency}
                      callback={onChangeFrequency}
                      options={values(timeframeOptions)}
                    />
                  </ControlForm>
                  <ControlForm style={{ marginTop: '16px', flexDirection: 'row' }}>
                    {!selectedIncome || selectedIncome.length <= 0 ? (
                      <>
                        {hasNoErrors ? (
                          <ControlButton style={{ margin: 0 }} onClick={onCreate}>
                            <span>Add Income</span>
                            <ControlIconContainer>
                              <Icon name="PLUS" size={20} />
                            </ControlIconContainer>
                          </ControlButton>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <ControlButton onClick={onUpdate}>
                          <span>Update Expense</span>
                        </ControlButton>
                        <ControlButton variant="OUTLINE" onClick={onCancel}>
                          <span>Cancel</span>
                        </ControlButton>
                      </>
                    )}
                  </ControlForm>
                </ControlFormGroup>
              </ControlLayout>
            </ControlMenu>
          )}
          <ControlContent>
            {/* <SelectWrapper>
              <Select
                config={selectConfig}
                activeId={incomeTimeframe}
                callback={handleIncomeTimeframe}
                variant={config.variant}
                iconName={config.iconName}
                options={values(timeframeOptions)}
              />
            </SelectWrapper> */}
            {/* {values(incomes) && values(incomes).length > 0 && (
              <Search items={values(incomes)} config={searchConfig} callback={handleSearchCallback} />
            )}  */}
            {/* <ControlGroup>
              <List
                items={localSearch || []}
                onEdit={onEdit}
                onRemove={onRemove}
                timeframe={incomeTimeframe}
                inflation={'0'}
                activeId={'-1'}
                height={300}
                variant={config.variant}
                placeholder="Looks like you haven't added an income yet. Try adding one."
                callback={onHandleListCallback}
              />
            </ControlGroup> */}
          </ControlContent>
        </ControlCustom>
      </ControlContainer>
    </ControlWrapper>
  )
}

const IncomeBuilder = (props) => {
  return (
    <PanelProvider>
      <IncomeBuilderFeature {...props} />
    </PanelProvider>
  )
}

export { IncomeBuilder }
