import styled, { css } from 'styled-components'
import { media } from '@utils/styles'

const HeaderContainer = styled.div.attrs({ className: 'Amount' })`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px;
  border-radius: 3px;
  background: #4fbe79;
  border: 1px solid #4fbe79;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  span {
    color: white;
    font-weight: 600;
  }
`

const FormGroup = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  label {
    margin-bottom: 8px;
  }
  div {
    &:first-child {
      margin: 0 !important;
    }
  }
`

const SelectWrapper = styled.div`
  margin-bottom: 16px;
  #selectDesktop {
    display: none;
  }
  #selectMobile {
    display: block;
  }

  ${media.sm`
#selectDesktop {
    display: block;
}
#selectMobile {
display: none;
}
`}
`

export { SelectWrapper, HeaderContainer, FormGroup }
