/* eslint-disable react/prop-types */
import React from 'react';

// const IconPath = (props) => {
//   const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props;
//   return (
//     <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
//       <circle cx="10" cy="10" r="6.25" stroke={stroke} strokeWidth="1.5" />
//       <path
//         d="M9.93815 12.9785C9.47949 12.9785 9.03682 12.9225 8.61015 12.8105C8.18882 12.6931 7.84482 12.5438 7.57815 12.3625L8.09815 11.1945C8.34882 11.3545 8.63682 11.4878 8.96215 11.5945C9.29282 11.6958 9.62082 11.7465 9.94615 11.7465C10.1648 11.7465 10.3408 11.7278 10.4742 11.6905C10.6075 11.6478 10.7035 11.5945 10.7622 11.5305C10.8262 11.4611 10.8582 11.3811 10.8582 11.2905C10.8582 11.1625 10.7995 11.0611 10.6822 10.9865C10.5648 10.9118 10.4128 10.8505 10.2262 10.8025C10.0395 10.7545 9.83149 10.7065 9.60215 10.6585C9.37815 10.6105 9.15149 10.5491 8.92215 10.4745C8.69815 10.3998 8.49282 10.3038 8.30615 10.1865C8.11949 10.0638 7.96749 9.90645 7.85015 9.71445C7.73282 9.51712 7.67415 9.26912 7.67415 8.97045C7.67415 8.63445 7.76482 8.33045 7.94615 8.05845C8.13282 7.78645 8.41015 7.56779 8.77815 7.40245C9.14615 7.23712 9.60482 7.15445 10.1542 7.15445C10.5222 7.15445 10.8822 7.19712 11.2342 7.28245C11.5915 7.36245 11.9088 7.48245 12.1862 7.64245L11.6982 8.81845C11.4315 8.67445 11.1675 8.56779 10.9062 8.49846C10.6448 8.42379 10.3915 8.38646 10.1462 8.38646C9.92749 8.38646 9.75149 8.41046 9.61815 8.45846C9.48482 8.50112 9.38882 8.55979 9.33015 8.63445C9.27149 8.70912 9.24215 8.79445 9.24215 8.89046C9.24215 9.01312 9.29815 9.11179 9.41015 9.18646C9.52749 9.25579 9.67949 9.31445 9.86615 9.36245C10.0582 9.40512 10.2662 9.45045 10.4902 9.49845C10.7195 9.54645 10.9462 9.60779 11.1702 9.68245C11.3995 9.75179 11.6075 9.84779 11.7942 9.97045C11.9808 10.0878 12.1302 10.2451 12.2422 10.4425C12.3595 10.6345 12.4182 10.8771 12.4182 11.1705C12.4182 11.4958 12.3248 11.7971 12.1382 12.0745C11.9568 12.3465 11.6822 12.5651 11.3142 12.7305C10.9515 12.8958 10.4928 12.9785 9.93815 12.9785ZM9.60215 13.8265V12.6825H10.5622V13.8265H9.60215ZM9.60215 7.44245V6.30645H10.5622V7.44245H9.60215Z"
//         fill={fill}
//       />
//     </svg>
//   );
// };

const IconPath = (props) => {
  const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="2.5" y="6.5" width="12" height="9" rx="0.5" stroke={stroke} />
      <path
        d="M17 14L17 5C17 4.44772 16.5523 4 16 4L4 4"
        stroke={stroke}
        strokeLinecap="round"
      />
      <path d="M7 6V16" stroke={stroke} strokeWidth="1.1" />
      <path d="M10 6V16" stroke={stroke} strokeWidth="1.1" />
    </svg>
  );
};

export default IconPath;
