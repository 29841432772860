import styled, { css } from 'styled-components';
import { mtFn, mbFn } from '@utils/styles';
import { media } from '@utils/styles';

const basicStyles = css`
  display: flex;
  flex-direction: row;
  border: none;
  box-sizing: border-box;
`;

const basicText = css`
  line-height: 14px;
  font-size: 14px;
  text-align: center;
`;

const pseudoStyles = css`
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const basicFunctions = css`
  ${(props) =>
    props.isDisabled &&
    css`
      color: #a9aeb9 !important;
      background: #f7f7f9 !important;
      border: 1px solid #f7f7f9 !important;
      cursor: not-allowed;
      &:hover {
        cursor: not-allowed;
        color: #a9aeb9 !important;
        background: #f7f7f9 !important;
        border: 1px solid #f7f7f9 !important;
        box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2) !important;
      }
      &:focus {
        cursor: not-allowed;
        box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2) !important;
      }
    `}
`;

const Button = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${mtFn};
  ${mbFn};
  background: #ff5a45;
  border-radius: 4px;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  color: #ffd5cf;
  justify-content: center;
  ${media.xs`
    width: 100%;
  `}
  ${media.sm`
    width: auto;
  `}
  padding: 10px 16px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  box-shadow: 0px -1px 0px 1px #ff8475;
  &:hover {
    background: #ff8475;
    box-shadow: 0px -1px 0px 1px #ff8475;
  }
  ${(props) =>
    props.variant === 'SAVE' &&
    css`
      margin-bottom: 0;
      span {
        color: white;
      }
      background: #4fbe79;
      box-shadow: 0px -1px 0px 1px #39975d;
      &:hover {
        color: white;
        background: #73cb95;
        box-shadow: 0px -1px 0px 1px #4fb476;
      }
    `}
  ${(props) =>
    props.variant === 'DISABLED' &&
    css`
      margin-bottom: 0;
      span {
        color: white;
      }
      background: #4fbe79;
      box-shadow: 0px -1px 0px 1px #a1fcc3;
      &:hover {
        color: white;
        background: #4fbe79;
        box-shadow: 0px -1px 0px 1px #4fbe79;
      }
    `}
  ${(props) =>
    props.isDisabled &&
    css`
      background: #a9aeb9;
    `}
`;

export { Button };
