/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = (props) => {
  const { stroke = 'red', fill = 'red' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" fill="transparent" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08271 1.34137C1.85907 3.60797 3.29176 5.44411 6.01832 7.6666C8.55234 9.73218 9.37216 10.9559 9.81408 13.333C9.93476 13.9822 10.1001 14.5592 10.1816 14.615C10.2629 14.671 10.6047 14.762 10.9409 14.8174C11.7932 14.9577 13.8717 15.7121 15.0527 16.3094C15.5975 16.585 16.623 17.2427 17.3314 17.7707C18.0401 18.2987 18.7697 18.8414 18.9531 18.9765C19.1364 19.1117 18.7659 18.6495 18.1297 17.9494C16.8291 16.5182 15.076 15.1732 13.3679 14.2963C11.6345 13.4062 11.5665 13.3587 11.3677 12.8995C10.9266 11.881 11.6404 11.2314 13.8193 10.6689C14.2997 10.545 14.8386 10.4266 15.0462 10.3498C15.2538 10.273 15.4493 10.1291 15.483 10.084C15.592 9.93839 15.2835 9.71187 14.9635 9.413C14.5326 9.01051 13.7173 8.50245 13.1813 8.31816C12.9258 8.23107 12.8006 8.22212 12.8179 8.41419C12.8352 8.49103 12.8622 8.62832 12.9044 8.79835C12.9518 9.64759 12.6952 9.74802 12.0589 9.14718C9.95036 7.1565 8.88211 6.47557 6.54638 5.63344C5.70589 5.33043 4.72138 4.91684 4.35867 4.71448C3.53578 4.25545 2.28351 2.94644 1.5969 1.8275C1.03137 0.906172 0.886825 0.769394 1.08271 1.34137Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconPath;
