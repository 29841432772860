import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

const theme = {
  font: {
    primary: `'IBM Plex Sans', sans-serif`,
  },
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
  },
  border: {
    defaultRadius: '7px',
    basic: '1px solid #dbdbdb',
  },
  button: {
    weight: 600,
    defaultSize: 'xlarge',
  },
  mq: {
    sm: 376,
    md: 768,
    lg: 1024,
    xl: 1280,
  },
  shadow: {
    primary: '0px 2px 2px 0px rgba(28, 17, 44, .15)',
    secondary: '0px 4px 14px 0px rgba(28, 17, 44, .10)',
  },
  colors: {
    orange: '#EF6C4D',
    grapefruit: '#EB3D2B',
    blueberry: '#5186EC',
    passionfruit: '#6C20CC',
    grape: '#BE8BFF',
    charcoal: '#1B1A29',
    ocean: '#1D2A43',
    greyXXXDark: '#5d646f',
    greyXXDark: '#8B8E94',
    greyXDark: '#B3B6BC',
    grey: '#BEC2CF',
    greyLight: '#D7DAE4',
    greyXLight: '#EDEEEF',
    greyXXLight: '#F6F5FC',
  },
  zIndex: {
    xs: 100,
    sm: 200,
    md: 300,
    lg: 400,
    xl: 500,
    xxl: 999,
  },
}

const layout = {
  grid: {
    gutterWidth: 32,
    gridColumns: 12,
    breakpoints: [375, 768, 1024, 1440],
    containerWidths: ['100%', 1224],
  },
  text: {
    align: {
      left: 'left',
      right: 'right',
      center: 'center',
    },
  },
  zIndex: {
    lg: 9999,
    med: 999,
    sm: 1050,
    xs: 100,
    header: 999,
    side: 9999,
    overlay: 9999,
  },
  align: {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  },
  custom: {
    wrapperTopMargin: 72,
    wrapperHeight: 40,
  },
  transition: {
    default: 'all 0.3s ease-in-out',
  },
  overlay: {
    relative: 'relative',
    absolute: 'absolute',
    fixed: 'fixed',
  },
  shadow: {
    default: '5px 15px 10px #00000024',
    xs: '0px 0px 16px -1px rgba(0, 0, 0, 0.14)',
    sm: '-3px -1px 13px 3px rgba(0,0,0, .1)',
    md: '3px 0px 15px 3px rgba(0, 0, 0, 0.14)',
    lg: '1px 2px 10px 2px rgba(0, 0, 0, 0.2)',
    xl: '-4px -1px 18px 4px rgba(0,0,0, .3)',
  },
}

const GlobalTheme = createGlobalStyle`
  iframe { display: hidden; }
  * {
    box-sizing: inherit;
    &:before {
      box-sizing: inherit;
    }
    &:after {
      box-sizing: inherit;
    }
  }
  body {
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    /* background: #1E1E1E; */
    /* background: rgb(31 38 45); */
    background:  rgb(245 245 245);
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
        background: #f2f2f2;
        border: none;
        box-shadow: none;
    }
  ::-webkit-scrollbar-thumb {
        background: #dfdfe7;
        border: none;
        box-shadow: none;
    }
  * {
    font-family: 'IBM Plex Sans', sans-serif;
  }
  h1,h2,h3,h4,h5 {
    font-family: 'IBM Plex Sans', sans-serif;
  }

  html, body {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .wrapper {
      box-sizing: border-box;
      min-height: 100%;
      display: flex;
      flex-direction: column;
  }
  .page-header, .page-footer {
      flex-grow: 0;
      flex-shrink: 0;
  }
  .page-body {
      flex-grow: 1;
  }

`

const ThemeProvider = ({ children }) => {
  return (
    <>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      <GlobalTheme />
    </>
  )
}

export { ThemeProvider }
