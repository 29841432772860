const defaultExpenses = {
  '9240604b-ceeb-4d4a-9fc1-32bf9a16759b': {
    id: '9240604b-ceeb-4d4a-9fc1-32bf9a16759b',
    value: 250,
    category: 'HOUSING',
    sequence: 0,
    label: 'Rent',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  'c7762b1e-0bfb-44c1-ade2-3930d05e2a7c': {
    id: 'c7762b1e-0bfb-44c1-ade2-3930d05e2a7c',
    value: 62,
    category: 'HOUSING',
    sequence: 1,
    label: 'Telstra',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  '40ce76ea-d136-4942-9bab-30561ce9e941': {
    id: '40ce76ea-d136-4942-9bab-30561ce9e941',
    value: 80,
    category: 'FOOD',
    sequence: 2,
    label: 'Uber Eats',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  '11c06479-e704-403e-b3cb-39519c3a5516': {
    id: '11c06479-e704-403e-b3cb-39519c3a5516',
    value: 11,
    category: 'ENTERTAINMENT',
    sequence: 3,
    label: 'XBOX',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  '8d4034d0-1f83-4e96-bffb-094a75c24f70': {
    id: '8d4034d0-1f83-4e96-bffb-094a75c24f70',
    value: 100,
    category: 'TRANSPORTATION',
    sequence: 4,
    label: 'Petrol',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  '8d4034d0-1f83-4e96-bffb-3322099': {
    id: '8d4034d0-1f83-4e96-bffb-3322099',
    value: 2,
    category: 'BUSINESS',
    sequence: 4,
    label: 'AWS Services',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  '8d4034d0-1f83-4e96-bffb-094322844': {
    id: '8d4034d0-1f83-4e96-bffb-094322844',
    value: 20,
    category: 'TRANSPORTATION',
    sequence: 4,
    label: 'Transurban Linkt',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  '611a5dc3-e121-494c-9ea5-470f239f390d': {
    id: '611a5dc3-e121-494c-9ea5-470f239f390d',
    value: 8,
    category: 'BUSINESS',
    sequence: 5,
    label: 'Github',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  '888304f8-7e6d-4f01-a021-48cce24852ab': {
    id: '888304f8-7e6d-4f01-a021-48cce24852ab',
    value: 80,
    category: 'FOOD',
    sequence: 7,
    label: 'Groceries',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  '888304f8-7e6d-4f01-a021-48cce3333999': {
    id: '888304f8-7e6d-4f01-a021-48cce3333999',
    value: 18,
    category: 'EGGS',
    sequence: 8,
    label: 'Groceries',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  '888304f8-7e6d-488832-a021-338e3333999': {
    id: '888304f8-7e6d-488832-a021-338e3333999',
    value: 10,
    category: 'MILK',
    sequence: 8,
    label: 'Groceries',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  'c4bd96bc-52d4-4d15-8cce-90959242b36f': {
    id: 'c4bd96bc-52d4-4d15-8cce-90959242b36f',
    value: 20,
    category: 'BUSINESS',
    sequence: 9,
    label: 'Vercel',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  '3b57e4f3-0069-469c-bb38-02035eb6d705': {
    id: '3b57e4f3-0069-469c-bb38-02035eb6d705',
    value: 15,
    category: 'BUSINESS',
    sequence: 11,
    label: 'Codesandbox',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  '3b57e4f3-0069-469c-3382782-28283822': {
    id: '3b57e4f3-0069-469c-3382782-28283822',
    value: 100,
    category: 'BUSINESS',
    sequence: 12,
    label: 'GitHub Copilot - AI',
    start: '',
    end: '',
    frequency: 'YEARLY',
  },
  '726f7686-7e11-4d0f-8a51-eca5b05b0cba': {
    id: '726f7686-7e11-4d0f-8a51-eca5b05b0cba',
    value: 183,
    category: 'INSURANCE',
    sequence: 13,
    label: 'Bupa Health Insurance',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  'b0088a73-997e-4914-b5d7-2f6ab9f31f16': {
    id: 'b0088a73-997e-4914-b5d7-2f6ab9f31f16',
    value: 17,
    category: 'ENTERTAINMENT',
    sequence: 17,
    label: 'Youtube',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  // '894ae956-facf-4c56-96fe-87dbf7850161': {
  //   id: '894ae956-facf-4c56-96fe-87dbf7850161',
  //   value: 50,
  //   category: 'HEALTH',
  //   sequence: 13,
  //   label: 'UMQ LTD Gym Fitness',
  //   start: '',
  //   end: '',
  //   frequency: 'FORTNIGHTLY',
  // },
  '894ae956-facf-4c56-96fe-87dbf733300023': {
    id: '894ae956-facf-4c56-96fe-87dbf733300023',
    value: 52,
    category: 'HEALTH',
    sequence: 16,
    label: 'Fitness First',
    start: '',
    end: '',
    frequency: 'FORTNIGHTLY',
  },
  '894ae956-facf-4c56-96fe-87dbf733300024': {
    id: '894ae956-facf-4c56-96fe-87dbf733300024',
    value: 126,
    category: 'HEALTH',
    sequence: 17,
    label: 'Fitness First Additional Member',
    start: '',
    end: '',
    frequency: 'MONTHLY',
  },
  'e79f509b-3e5c-4063-8cf4-32ac681f4ec8': {
    id: 'e79f509b-3e5c-4063-8cf4-32ac681f4ec8',
    value: 2357.26,
    category: 'TRANSPORTATION',
    sequence: 18,
    label: 'NRMA Car Insurance',
    start: '',
    end: '',
    frequency: 'YEARLY',
  },
  'b1c4f0a2-993a-44bb-a471-1ce0df458b34': {
    id: 'b1c4f0a2-993a-44bb-a471-1ce0df458b34',
    value: 180,
    category: 'TRANSPORTATION',
    sequence: 19,
    label: 'Car Rego',
    start: '',
    end: '',
    frequency: 'YEARLY',
  },
  'e2333ba3-e721-4cc6-9f2b-1e6a8b144e26': {
    id: 'e2333ba3-e721-4cc6-9f2b-1e6a8b144e26',
    value: 2000,
    category: 'TRANSPORTATION',
    sequence: 20,
    label: 'Car Maintenance ',
    start: '',
    end: '',
    frequency: 'YEARLY',
  },
  'e2333ba3-e721-4cc6-9f2b-1e6a8b144e26': {
    id: 'e2333ba3-e721-4cc6-9f2b-1e6a8b144e26',
    value: 7,
    category: 'TRANSPORTATION',
    sequence: 21,
    label: 'Car Cleaning',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  '88s0182222-e721-4cc6-9f2b-1e6a8b144e26': {
    id: '88s0182222-e721-4cc6-9f2b-1e6a8b144e26',
    value: 40,
    category: 'FOOD',
    sequence: 21,
    label: 'Weekend Outing',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
  '22s0182222-e721-4cc6-9f2b-1e6a8b144e22': {
    id: '22s0182222-e721-4cc6-9f2b-1e6a8b144e226',
    value: 30,
    category: 'FOOD',
    sequence: 21,
    label: 'Strawberries / Blueberries / Rasberries',
    start: '',
    end: '',
    frequency: 'WEEKLY',
  },
}

export { defaultExpenses }
