import React from 'react'

import { Grid, Row, Col } from '@common/Grid'

import { ExpenseBuilder } from '@features/ExpenseBuilder'
import { IncomeBuilder } from '@features/IncomeBuilder'
import { ExportView } from '@features/ExportView'
import { BalanceBuilder } from '@features/BalanceBuilder'
import { Header } from '@features/Header'
import { Creditors } from '@features/Creditors'

import { Footer, Wrapper, Main } from './Paydai.styled'

const Paydai = () => (
  <Wrapper className="flex-wrapper">
    <Header />
    <Main className="main">
      <Wrapper>
        <Grid marginX={12}>
          <Row mb={16}>
            <Col xs={12} sm={12} md={6}>
              <Row>
                <Col>
                  <ExportView />
                  <IncomeBuilder id="income-builder" />
                </Col>
              </Row>
              <Row mt={16} isHidden="mobile">
                <Col xs={12} sm={12} md={12}>
                  <BalanceBuilder id="balance-builder" />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={6} mtxxs={16} mtxs={16} mtsm={16}>
              <ExpenseBuilder id="expense-builder" />
            </Col>
          </Row>
          <Row mb={12} isHidden="desktop">
            <Col xs={12} sm={12} md={12}>
              <BalanceBuilder id="balance-builder" />
            </Col>
          </Row>
          <Row mb={64}>
            <Col xs={12} sm={6}></Col>
            <Col xs={12} sm={6}>
              <div id="save-control" />
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    </Main>
    <Footer className="footer">
      <Grid marginX={12}>
        <h2>Credits</h2>
        <hr />
        <Creditors />
      </Grid>
    </Footer>
  </Wrapper>
)

export default Paydai
