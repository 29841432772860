import styled, { css } from 'styled-components'

const Footer = styled.footer`
  background: #2e3546;
  padding: 8px 24px 32px 24px;
  h2 {
    color: white;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 38px;
`
const Main = styled.div``

export { Footer, Wrapper, Main }
