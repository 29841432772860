import styled, { css } from 'styled-components';
import { scrollBarStyle } from '@utils/styles';

const MultiContainer = styled.div`
  margin: 16px 0 0 0;
  border: 2px solid #dadada;
  display: flex;
  padding: 16px;
  border-radius: 6px;
  flex-direction: column;
  width: 100%;
`;

const MultiGroup = styled.ul`
  display: flex;
  list-style: none;
  width: 100%;
  margin: 0;
  margin-top: 16px;
  flex-direction: row;
  padding: 0 0 16px 0;
  overflow-x: auto;
  height: 130px;
  ${scrollBarStyle};
`;

const MultiSuccess = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: auto;
`;

const MultiLabel = styled.span`
  word-wrap: break-word;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin: 0;
`;

const MultiItemContainer = styled.li`
  list-style: none;
  padding: 16px 12px 16px 16px;
  background: white;
  border-radius: 6px;
  margin-left: 8px;
  max-width: 164px;
  min-width: 164px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  word-wrap: break-word;
  width: 100%;
  cursor: pointer;
  border: 2px solid rgb(204, 204, 204);
  button {
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 4px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #dadada;
    margin-left: auto;
    background: transparent;
    &:hover {
      cursor: pointer;
      background: #ff5a45;
      svg {
        path,
        line {
          stroke: white;
        }
      }
    }
  }
  &:first-child {
    margin-left: 0;
  }
  ${(props) =>
    props.isActive &&
    css`
      border: 2px solid #ff5a45;
    `}
`;

export {
  MultiContainer,
  MultiLabel,
  MultiSuccess,
  MultiGroup,
  MultiItemContainer,
};
