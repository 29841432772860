/* eslint-disable react/prop-types */
import React from 'react';

const Refresh = (props) => {
  const { fill = '#a6adb6', stroke = 'none' } = props;
  return (
    <svg width="20px" height="20px" viewBox="0 0 17.32 16.5">
      <g stroke={stroke} fill={fill}>
        <path d="M16.575 1.629a.609.609 0 00-.724.468l-.443 2.067A7.923 7.923 0 00.651 8.172a.6095.6095 0 101.219 0 6.7 6.7 0 0112.5-3.367l-2.032-.435a.60948523.60948523 0 00-.255 1.192l3.488.747a.61.61 0 00.724-.468l.747-3.488a.61.61 0 00-.467-.724z" />
        <path d="M15.967 7.475a.609.609 0 00-.589.629 6.7 6.7 0 01-6.479 6.922h-.223a6.7 6.7 0 01-5.7-3.178l2.118.568a.60934083.60934083 0 00.316-1.177l-3.445-.924a.609.609 0 00-.747.431l-.924 3.445a.609.609 0 101.173.321l.519-1.936a7.919 7.919 0 006.681 3.673h.264a7.923 7.923 0 007.657-8.18.609.609 0 00-.621-.594z" />
      </g>
    </svg>
  );
};

export default Refresh;
