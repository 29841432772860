import styled, { css } from 'styled-components';
import { mtFn, mbFn, mlFn, mrFn, media } from '@utils/styles';

const basicStyles = css`
  display: inline-flex;
  flex-direction: row;
  border: none;
  justify-content: center;
  box-sizing: border-box;
`;

const basicText = css`
  line-height: 20px;
  font-size: 14px;
  color: #9e9ba6;
  text-align: center;
`;

const Primary = styled.button`
  ${basicStyles};
  span {
    ${basicText};
  }
  ${mtFn};
  ${mbFn};
  ${mrFn};
  ${mlFn};
  padding: 10px 16px;
  background: #57535c;
  border-radius: 4px;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  color: white;
  text-align: center;
  box-shadow: 0px -1px 0px 1px #646169;
  cursor: pointer;
  max-width: 160px;
  &:hover {
    background: #646169;
  }
  ${(props) =>
    props.variant === 'SAVE' &&
    css`
      margin-bottom: 0;
      span {
        color: white;
      }
      background: #4fbe79;
      box-shadow: 0px -1px 0px 1px #4fbe79;
      &:hover {
        color: white;
        background: #4fbe79;
        box-shadow: 0px -1px 0px 1px #4fbe79;
      }
    `}
`;

export { Primary };
