const timeframeOptions = {
  YEARLY: { id: 'YEARLY', value: 'YEARLY', label: 'Yearly', index: 0 },
  QUARTERLY: {
    id: 'QUARTERLY',
    value: 'QUARTERLY',
    label: 'Quarterly',
    index: 1,
  },
  MONTHLY: { id: 'MONTHLY', value: 'MONTHLY', label: 'Monthly', index: 2 },
  FORTNIGHTLY: {
    id: 'FORTNIGHTLY',
    value: 'FORTNIGHTLY',
    label: 'Fortnightly',
    index: 3,
  },
  WEEKLY: { id: 'WEEKLY', value: 'WEEKLY', label: 'Weekly', index: 4 },
  DAILY: { id: 'DAILY', value: 'DAILY', label: 'Daily', index: 5 },
  ONCE: { id: 'ONCE', value: 'ONCE', label: 'Once', index: 6 },
}

const timeframeOptionsArray = [
  { label: 'DAILY', value: 1 },
  { label: 'WEEKLY', value: 7 },
  { label: 'FORTNIGHTLY', value: 14 },
  { label: 'MONTHLY', value: 31 },
  { label: 'QUARTERLY', value: 91 },
  { label: 'YEARLY', value: 365 },
  { label: 'ONCE', value: 365 },
]

const timeframeOptionKeys = {
  DAILY: 1,
  WEEKLY: 7,
  FORTNIGHTLY: 14,
  MONTHLY: 31,
  QUARTERLY: 91,
  YEARLY: 365,
  ONCE: 365,
}

export { timeframeOptions, timeframeOptionsArray, timeframeOptionKeys }
