/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

const IconPath = (props) => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" fill="white" />
      <path
        d="M4 10H16"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M10 16L10 4"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
    </svg>
  );
};
// const IconPath = (props) => {
//   const { fill = 'none', stroke = '#A9AEB9' } = props;
//   return (
//     <svg width="16" height="16" viewBox="0 0 16 16" fill={fill}>
//       <path
//         d="M3 7.99998H13M8.00002 13V3"
//         stroke={stroke}
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   );
// };

export default IconPath;
