import styled from 'styled-components';
import {
  mtFn,
  mbFn,
  colorFn,
  widthFn,
  preventSelectFn,
  fontSizeFn,
  linkFn,
  weightFn,
  centerFn,
  lineHeightFn,
} from '@utils/styles';

const TextLink = styled.a`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin: 0;
  line-height: 20px;
  user-select: none;
  ${preventSelectFn};
  ${lineHeightFn};
  ${fontSizeFn};
  ${weightFn};
  ${widthFn};
  ${colorFn};
  ${linkFn};
  ${centerFn};
  ${mbFn};
  ${mtFn};
  cursor: pointer;
`;

const TextParagraph = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin: 0;
  color: #9e9ba6;
  font-weight: 300;
  text-transform: none;
  user-select: none;
  ${lineHeightFn};
  ${fontSizeFn};
  ${weightFn};
  ${widthFn};
  ${colorFn};
  ${linkFn};
  ${centerFn};
  ${mbFn};
  ${mbFn};
  span {
    font-size: 14px;
    margin-left: 8px;
    color: grey;
  }
`;

const TextSpan = styled.span`
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin: 0;
  text-transform: none;
  line-height: 20px;
  user-select: none;
  ${preventSelectFn};
  ${lineHeightFn};
  ${fontSizeFn};
  ${weightFn};
  ${widthFn};
  ${colorFn};
  ${linkFn};
  ${centerFn};
  ${mbFn};
  ${mtFn};
`;

export { TextLink, TextParagraph, TextSpan };
