import styled, { css } from 'styled-components';

const SearchStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  p {
    font-size: 14px;
    margin: 0;
    margin-left: 8px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  ul {
    margin: 0;
    padding: 0 0 0 24px;
    li {
      font-size: 14px;
    }
  }
`;

const SearchResultsStyled = styled.div`
  width: 100%;
`;

const Result = styled.div`
  &:first-child {
    margin-top: 0;
  }
  span.occurrence {
    color: white;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px;
    background: black;
    border-radius: 5px;
  }
  position: relative;
  margin-top: 12px;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  flex-grow: 0;
  margin: 12px 0px;
  padding: 16px;
  border: 1px solid #efefef;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 2px 2px rgb(28 17 44 / 9%);
  span {
    font-size: 14px;
    font-weight: 500;
    color: #121b2d;
    display: flex;
    margin-right: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-align: left;
    max-width: 300px;
  }
  ${(props) => {
    const { occurrence } = props;
    if (occurrence === 1)
      return css`
        background: red;
      `;
    if (occurrence === 2)
      return css`
        background: orange;
      `;
    if (occurrence === 3)
      return css`
        background: yellow;
      `;
    if (occurrence === 4)
      return css`
        background: green;
      `;
  }}
`;

export { SearchStyled, SearchResultsStyled, Result };
