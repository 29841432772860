import styled, { css } from 'styled-components';
import { media } from '@utils/styles';

const MenuContainer = styled.div`
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  top: 8px;
  z-index: 100;
  /* background: white; */
  /* background: #3a393e; */
  background: #45434a;
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  border-radius: 8px 8px 8px 8px;
  ${media.md`
    width: 50%;
    `}
  ${(props) =>
    props.isActive &&
    css`
      right: 8px;
    `}
`;

const MenuContent = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

const MenuOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(29, 42, 67, 0.24); */
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
  border-radius: 8px;
  display: block;
`;

const MenuButton = styled.button`
  outline: none;
  position: absolute;
  z-index: 100;
  top: 16px;
  right: 16px;
  width: 48px;
  border-radius: 4px;
  background: transparent;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgba(224, 224, 224, 0.29);
  }
  &:focus {
    outline: none;
    cursor: pointer;
  }
`;

const MenuCloseButton = styled.button`
  outline: none;
  position: absolute;
  width: 32px;
  height: 32px;
  left: 12px;
  top: 12px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  /* background: #2e3546; */
  background: transparent;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  &:focus {
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  &:hover {
    cursor: pointer;
    background: #4f4e54;
    border: 1px solid #4f4e54;
  }
`;

const MenuControl = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  /* border-bottom: 2px solid #e0e0e0; */
  border-bottom: 2px solid #45434a;
  justify-content: flex-start;
  position: relative;
  padding-top: 21px;
  padding-bottom: 21px;
  /* background: #3a393e; */
  background: #45434a;
  border-radius: 4px 4px 0 0;
`;

const MenuTitle = styled.h2`
  font-size: 14px;
  line-height: 20px;
  /* color: #323439; */
  color: white;
  text-align: center;
  font-weight: 500;
  width: 100%;
  margin: 0;
  ${media.sm`
  font-size: 16px;`}
`;

export {
  MenuContainer,
  MenuContent,
  MenuCloseButton,
  MenuControl,
  MenuTitle,
  MenuButton,
  MenuOverlay,
};
