import Types from './Types';

const initialState = {
  isMenuOpen: false,
};

const Reducer = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case Types.SET_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: true,
      };
    case Types.SET_MENU_CLOSED:
      return {
        ...state,
        isMenuOpen: false,
      };
    default:
      return state;
  }
};

export { initialState };

export default Reducer;
