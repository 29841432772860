import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@common/Icon';

const MultiCheckLabel = styled.span`
  font-size: 14px;
  line-height: 21px;
`;

const MultiCheckContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const MultiCheckIcon = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  ${(props) =>
    props.isHidden &&
    css`
      display: flex;
    `}
`;

const MultiCheck = ({ isActive, label }) => {
  return (
    <MultiCheckContainer>
      <MultiCheckIcon>
        {isActive ? (
          <Icon name={`CHECKBOX_FILLED`} size={20} />
        ) : (
          <Icon name={`CHECK_OUTLINE`} stroke="#e3e5eb" size={20} />
        )}
      </MultiCheckIcon>
      {label && <MultiCheckLabel>{label}</MultiCheckLabel>}
    </MultiCheckContainer>
  );
};

MultiCheck.defaultProps = {
  isActive: false,
  label: 'Select',
};

export { MultiCheck };
