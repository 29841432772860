import React, { useEffect, useState, useContext } from 'react'
import { ColorPicker } from './ColorPicker'
import { Primary } from '@common/Button'
import { prop, values, dissoc } from 'ramda'
import { Context, Types } from '../../state/Provider'
import { Input } from '@common/Input'
import { Icon } from '@common/Icon'
import {
  FormGroup,
  Heading,
  CategoryGroup,
  Item,
  Label,
  CategoryControls,
  Remove,
  InputGroup,
  Swatch,
} from './CategoryBuilder.styled'

const initialState = {
  swatchId: '',
  label: '',
  swatch: '',
}

const CategoryBuilder = ({ ...props }) => {
  const [localState, localDispatch] = useContext(Context)
  const list = prop('categories')(localState)
  const handleCallback = (cb) => {
    const { type, data } = cb
    if (type === 'ADD') {
      localDispatch({ type: Types.ADD_CATEGORY, data })
    } else if (type === 'REMOVE') {
      localDispatch({ type: Types.REMOVE_CATEGORY, data })
    }
  }

  return <Builder list={list} callback={handleCallback} {...props} />
}

const Builder = ({ heading = '', list = {}, callback = () => {}, label = '' }) => {
  const [state, setState] = useState(initialState)
  const items = values(list)

  const handleColorCallback = (data) => {
    const swatchId = prop('id')(data)
    const swatch = prop('value')(data)

    setState((state) => ({ ...state, swatchId, swatch }))
  }

  const onLocalRemove = (id) => {
    const removedById = dissoc(id, list)
    callback({ type: 'REMOVE', data: removedById })
  }

  const onLocalAdd = () => {
    const { label, swatch } = state
    if (!label || label.length <= 0 || !swatch || swatch.length <= 0) return

    const data = {
      id: label.toUpperCase(),
      label,
      swatch,
    }

    callback({ type: 'ADD', data })
  }

  const onChange = (e) => {
    const { value, name } = e.target

    setState((state) => ({ ...state, [name]: value }))
  }

  const renderCategories = (items = []) => {
    if (!items || items.length <= 0) return null
    const results = items.map((item) => (
      <Item key={item.id}>
        <Label>{item.label}</Label>
        <CategoryControls>
          <Swatch bgColor={item.swatch} />
          <Remove onClick={() => onLocalRemove(item.id)}>
            <Icon name="TRASH" stroke="#84828D" size={20} />
          </Remove>
        </CategoryControls>
      </Item>
    ))
    return <CategoryGroup>{results}</CategoryGroup>
  }

  const renderCreateCategory = (
    <>
      <InputGroup>
        {label && (
          <Label weight={500} htmlFor="label">
            {label}
          </Label>
        )}
        <Input
          name="label"
          id="label"
          variant="tertiary"
          placeholder="Category name"
          value={state.label}
          onChange={onChange}
        />
      </InputGroup>
      <ColorPicker activeId={state.swatchId} callback={handleColorCallback} />
      <Primary onClick={onLocalAdd} mb={8}>
        <span>Add</span>
      </Primary>
    </>
  )

  return (
    <FormGroup>
      {heading && <Heading htmlFor="value">{heading}</Heading>}
      {renderCategories(items)}
      {renderCreateCategory}
    </FormGroup>
  )
}

export { CategoryBuilder }
