// import React from 'react';
import React from 'react'
import Paydai from '@pages/paydai'

import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'src/state/Provider/State'
import { ThemeProvider } from '@common/Theme'

// import Rebuild from '@pages/rebuild';
// import Settings from '@pages/settings';
// import Compare from '@pages/compare';
// const App = () => {
//   return <Payday />;
// };

// export default App;

// import styled from 'styled-components';

// import pdfMake from 'pdfmake';
// import html2canvas from 'html2canvas';

// const StyledDiv = styled.div``;
// const Content = styled.div`
//   width: 100%;
//   background: blue;
//   left: 0;
//   top: 0;
//   height: 200px;
// `;

// const DownloadPdf = ({ children, id }) => {
//   const genPdf = () => {
//     const pdfTable = document.getElementById(id);
//     html2canvas(pdfTable).then((canvas) => {
//       const imgObj = {
//         image: canvas.toDataURL(),
//         width: 600,
//         style: {
//           alignment: 'center',
//         },
//       };
//       const documentDefinition = {
//         content: [imgObj],

//         pageSize: 'A4',
//         pageOrientation: 'landscape',
//         pageMargins: [40, 60, 40, 60],
//       };
//       const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
//       pdfDocGenerator.download();
//     });
//   };

//   return (
//     <StyledDiv>
//       <div id={id}>
//         <Content>{children}</Content>
//       </div>
//       <button onClick={genPdf}>Generate PDF for Table</button>
//     </StyledDiv>
//   );
// };
// <Route path="/rebuild" exact element={<Rebuild />} />

const Development = () => (
  <Router>
    <Routes>
      <Route path="/" exact element={<Paydai />} />
    </Routes>
  </Router>
)
const environment = process.env.NODE_ENV ? process.env.NODE_ENV : 'development'

const App = () => {
  return (
    <Provider>
      <ThemeProvider>{environment === 'production' ? <Paydai /> : <Development />}</ThemeProvider>
    </Provider>
  )
}

export default App
