import React, { useEffect } from 'react'
import pdfMake from 'pdfmake'
import html2canvas from 'html2canvas'
import { Icon } from '@common/Icon'

import { DownloadButton } from './Download.styled'

const Download = ({ id = '', label = '', icon, config = {} }) => {
  useEffect(() => {
    const foundElem = document.getElementsByTagName('iframe')[0]
    if (foundElem) {
      foundElem.style.display = 'none'
    }
  }, [])
  const genPdf = () => {
    const pdfContent = document.getElementById(id)
    html2canvas(pdfContent).then((canvas) => {
      const imgObj = {
        image: canvas.toDataURL(),
        fit: [1000, 500],
        style: {
          alignment: 'center',
        },
      }
      const documentDefinition = {
        content: [imgObj],
        pageSize: 'A3',
        pageOrientation: 'portrait',
        pageMargins: [40, 40, 40, 40],
      }
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
      pdfDocGenerator.download()
    })
  }

  return (
    <>
      <DownloadButton onClick={genPdf}>
        <Icon name="DOWNLOAD" stroke="rgb(21, 25, 27)" size={26} />
      </DownloadButton>
    </>
  )
}

export { Download }
