import styled, { css } from 'styled-components'
import { media } from '@utils/styles'

const ListItemWrapper = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  margin: 12px 0px;
  &:first-child {
    margin-top: 0;
  }
`

const ListGroup = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  background: transparent;
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `};
  &::-webkit-scrollbar-track {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background: none;
    border: none;
    box-shadow: none;
    width: 14px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background: #eeeff1;
    border: none;
    box-shadow: none;
    border-radius: 6px;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      opacity: 1;
    }
    &::-webkit-scrollbar-thumb {
      opacity: 1;
    }
  }
`

const TotalGroup = styled.div.attrs({ className: 'TotalGroup' })`
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  padding-right: 6px;
  border-radius: 0 8px 8px 0px;
  color: green;
  ${media.sm`
    padding-left: 14px;
    padding-right: 6px;
    font-size: 14px;
  `}
  &.asExpense {
    color: white;
    border: 1px solid #b20000;
    background: #ff6767;
  }
  &.asIncome {
    color: white;
    background: green;
    border: 1px solid green;
    background: #4fbe79;
  }
`

const Amount = styled.div.attrs({ className: 'Amount' })`
  position: relative;
  font-size: 14px;
  color: white;
  padding: 6px;
  margin-right: 4px;
  border-radius: 4px;
  line-height: 18px;
  font-weight: 500;
  .asIncome & {
    background: #4fbe79;
    border: 1px solid #4fbe79;
    span {
      display: flex;
    }
  }
  .asExpense & {
    background: #ff6767;
    border: 1px solid #b20000;
    span {
      display: flex;
    }
  }
  ${media.sm`
    font-size: 14px;
  `}
`

const ListItem = styled.div`
  padding: 10px 10px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  box-shadow: 0px 2px 2px rgba(28, 17, 44, 0.1);
  border-radius: 8px;
  align-self: stretch;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  flex-direction: row;
  max-width: 100%;

  ${media.sm`
    width: calc(100% - 80px);
    max-width: calc(100% - 80px);
  `}
  &.asExpense {
    border: 1px solid rgb(31, 36, 40);
  }
  &.asIncome {
    border: 1px solid green;
  }
  ${(props) =>
    props.isSelected &&
    css`
      &.asExpense {
        background: rgb(31, 36, 40);
        border: 1px solid #b20000;
      }
      &.asIncome {
        background: #4fbe79;
      }
    `}
  .asIncome & {
    border: 1px solid #4fbe79;
  }
  &:first-child {
    margin-top: 0;
  }
  ${media.sm`
  width: 100%;
  max-width: 100%;
  padding: 12px 16px;
  `}
`

const Push = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin-left: auto;
`

const PlaceholderTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #465265;
  flex-grow: 1;
  white-space: wrap;
  display: block;
  ${media.sm`
      max-width: 100%;
      font-size: 16px;
    `}
`

const CategoryWrapper = styled.div`
  padding: 8px 8px;
  margin: 0;
  border-radius: 0 6px 6px 6px;
  ${(props) =>
    props.swatch &&
    css`
      background: ${props.swatch};
    `}
`

const CategoryTotalContainer = styled.div`
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  display: inline-flex;

  span.categoryWrapper {
    font-size: 16px;
    font-weight: 600;
    color: #465265;
    background: white;
    border-top: 1px solid #465265;
    border-bottom: 1px solid #465265;
    border-left: 1px solid #465265;
    padding: 8px;
    border-radius: 4px 0px 0px 4px;
  }

  span.titleCase {
    text-transform: capitalize;
  }
  span.categoryTimeframe {
    font-size: 13px;
  }

  span.categoryTotal {
    font-size: 16px;
    font-weight: 600;
    color: white;
    padding: 8px;
    border: 1px solid #b20000;
    background: #ff6767;
    border-radius: 0px 4px 4px 0px;
  }
`

const CategoryName = styled.div`
  display: inline-flex;
  max-width: 200px;
  border-radius: 6px 6px 0 0;
  background: red;
  position: relative;
  top: 1px;
  ${(props) =>
    props.swatch &&
    css`
      background: ${props.swatch};
    `};
  span {
    font-size: 14px;
    display: block;
    padding: 6px 8px 2px 9px;
    font-weight: 600;
    line-height: 20px;
    color: black;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #465265;
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  margin-right: 4px;
  width: calc(100% - 284px);
  ${media.xs`
      width: 100%;
      max-width: 100px;
      font-size: 16px;
    `}
  ${media.sm`
      width: 100%;
      max-width: 100%;
      font-size: 16px;
    `}
  ${media.md`
      width: 100%;
      max-width: 100%;
      font-size: 16px;
    `}
  ${(props) =>
    props.error &&
    css`
      max-width: 300px;
      color: pink;
    `}
`

const Repeat = styled.div`
  padding: 6px;
  display: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
  line-height: 18px;
  margin-right: 4px;
  max-width: 108px;
  ${(props) =>
    props.asMobile &&
    css`
      ${media.sm`
        display: none;
      `}
      display: inline-block;
    `}
  ${(props) =>
    props.asDesktop &&
    css`
      ${media.sm`
        display: inline-block;
      `}
      display: none
    `}
  ${(props) =>
    props.type === 'MONTHLY' &&
    css`
      color: #f59e0b;
      background: #fef3c7;
      border: 1px solid #f59e0b;
    `}
  ${(props) =>
    props.type === 'FORTNIGHTLY' &&
    css`
      color: #a9aeb9;
      background: #f4f5f8;
      border: 1px solid #a9aeb9;
    `}
${(props) =>
  props.type === 'WEEKLY' &&
  css`
    color: #42a4ff;
    background: #edf6ff;
    border: 1px solid #42a4ff;
  `}
${(props) =>
  props.type === 'YEARLY' &&
  css`
    color: #42a4ff;
    background: #edf6ff;
    border: 1px solid #42a4ff;
  `}
${(props) =>
  props.type === 'ONCE' &&
  css`
    color: #4fbe79;
    background: #edf6ff;
    border: 1px solid #4fbe79;
  `}
${(props) =>
  props.type === 'DAILY' &&
  css`
    color: #4fbe79;
    background: rgba(66, 164, 255, 0.1);
    border: 1px solid #4fbe79;
  `}
`

export {
  ListItemWrapper,
  ListGroup,
  TotalGroup,
  Amount,
  ListItem,
  Push,
  Title,
  Repeat,
  CategoryWrapper,
  CategoryTotalContainer,
  CategoryName,
  PlaceholderTitle,
}
