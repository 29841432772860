import styled, { css } from 'styled-components'
import { media } from '@utils/styles'

const ListItemWrapper = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  margin: 12px 0px;
  &:first-child {
    margin-top: 0;
  }
`

const ListGroup = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  background: transparent;
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `};
  &::-webkit-scrollbar-track {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background: none;
    border: none;
    box-shadow: none;
    width: 14px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background: #eeeff1;
    border: none;
    box-shadow: none;
    border-radius: 6px;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      opacity: 1;
    }
    &::-webkit-scrollbar-thumb {
      opacity: 1;
    }
  }
`

const TotalGroup = styled.div.attrs({ className: 'TotalGroup' })`
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  padding-right: 6px;
  border-radius: 0 8px 8px 0px;
  color: green;
  ${media.sm`
    padding-left: 14px;
    padding-right: 6px;
    font-size: 14px;
  `}
  &.asExpense {
    color: white;
    border: 1px solid #b20000;
    background: #ff6767;
  }
  &.asIncome {
    color: white;
    background: green;
    border: 1px solid green;
    background: #4fbe79;
  }
`

const ListTotal = styled.div.attrs({ className: 'ListTotal' })`
  position: relative;
  font-size: 14px;
  color: white;
  padding: 6px;
  margin-right: 4px;
  border-radius: 4px;
  line-height: 18px;
  font-weight: 500;
  .asIncome & {
    background: #4fbe79;
    border: 1px solid #4fbe79;
    span {
      display: flex;
    }
  }
  .asExpense & {
    background: #ff6767;
    border: 1px solid #b20000;
    span {
      display: flex;
    }
  }
  ${media.sm`
    font-size: 14px;
  `}
`

const ListItem = styled.div`
  padding: 10px 10px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  box-shadow: 0px 2px 2px rgba(28, 17, 44, 0.1);
  border-radius: 8px;
  align-self: stretch;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  ${media.sm`
  width: 100%;
  max-width: 100%;
  `}
  &.asExpense {
    border: 1px solid #ff9f9f;
  }
  &.asIncome {
    border: 1px solid green;
  }
  ${(props) =>
    props.isSelected &&
    css`
      &.asExpense {
        background: #ff9f9f;
        border: 1px solid #b20000;
      }
      &.asIncome {
        background: #4fbe79;
      }
    `}
  .asIncome & {
    border: 1px solid #4fbe79;
  }
  &:first-child {
    margin-top: 0;
  }
  ${media.sm`
  width: 100%;
  max-width: 100%;
  padding: 12px 16px;
  `}
  ${(props) =>
    props.error &&
    css`
      border: 1px dashed #dadce5;
      box-shadow: none;
      background: white;
      color: black;
      max-width: 100%;
      max-height: 60px;
      width: 100%;
      margin: 0;
      span {
        color: black;
        line-height: 24px;
        font-size: 14px;
        font-weight: 400;
      }
    `}
`

const Push = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  margin-left: auto;
`

const Title = styled.span.attrs({ className: 'ListTitle' })`
  font-size: 14px;
  font-weight: 500;
  color: #465265;
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  max-width: 90px;
  margin-right: 4px;
  width: 90px;
  ${media.xs`
      /* max-width: 100px; */
      width: 100%;
      font-size: 16px;
    `}
  ${media.sm`
      max-width: 400px;
      width: 100%;
      font-size: 16px;
    `}
  ${media.md`
      max-width: 500px;
      font-size: 16px;
      width: 100%;
    `}
  ${media.lg`
      max-width: 100%;
      font-size: 16px;
    `}
  ${(props) =>
    props.error &&
    css`
      max-width: 100%;
      text-overflow: unset;
      white-space: normal;
    `}
`

const Repeat = styled.div`
  padding: 6px;
  display: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
  line-height: 18px;
  margin-right: 4px;
  ${(props) =>
    props.asMobile &&
    css`
      ${media.sm`
        display: none;
      `}
      display: inline-block;
    `}
  ${(props) =>
    props.asDesktop &&
    css`
      ${media.sm`
        display: inline-block;
      `}
      display: none
    `}
  ${(props) =>
    props.type === 'MONTHLY' &&
    css`
      color: #f59e0b;
      background: #fef3c7;
      border: 1px solid #f59e0b;
    `}
  ${(props) =>
    props.type === 'FORTNIGHTLY' &&
    css`
      color: #a9aeb9;
      background: #f4f5f8;
      border: 1px solid #a9aeb9;
    `}
${(props) =>
  props.type === 'WEEKLY' &&
  css`
    color: #42a4ff;
    background: #edf6ff;
    border: 1px solid #42a4ff;
  `}
${(props) =>
  props.type === 'YEARLY' &&
  css`
    color: #42a4ff;
    background: #edf6ff;
    border: 1px solid #42a4ff;
  `}
${(props) =>
  props.type === 'ONCE' &&
  css`
    color: #4fbe79;
    background: #edf6ff;
    border: 1px solid #4fbe79;
  `}
${(props) =>
  props.type === 'DAILY' &&
  css`
    color: #4fbe79;
    background: rgba(66, 164, 255, 0.1);
    border: 1px solid #4fbe79;
  `}
`

export { ListItemWrapper, ListGroup, TotalGroup, ListTotal, ListItem, Push, Title, Repeat }
