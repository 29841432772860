import React, { useEffect, useState } from 'react'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'
import { MultiCheck } from './MultiCheck'
import { MultiExpander } from './MultiExpander'
import PropTypes from 'prop-types'
import { alter, alterAll, getPriority, getTrimmedString } from './MultiUtils'
import {
  MultiContainer,
  MultiGroup,
  MultiLabel,
  MultiSuccess,
  MultiItemContainer,
} from './MultiSelect.styled'

const config = {
  layout: {},
}

const MultiItem = ({ item, onSelect, ...props }) => {
  const onLocalSelect = (item) => onSelect(item)

  return (
    <MultiItemContainer onClick={() => onLocalSelect(item)} isActive={item.active} {...props}>
      <MultiLabel>{getTrimmedString({ text: item.label, max: 50 }) || 'Empty'}</MultiLabel>
      {item.active && (
        <MultiSuccess>
          <Icon name="CHECK_ACTIVE" size={20} />
        </MultiSuccess>
      )}
    </MultiItemContainer>
  )
}

MultiItem.defaultProps = {
  onSelect: () => {},
  item: {},
}

MultiItem.propTypes = {
  onSelect: PropTypes.func,
  item: PropTypes.shape({
    sequence: PropTypes.number,
    label: PropTypes.string,
    active: PropTypes.bool,
    id: PropTypes.string,
  }),
}

const MultiSelect = ({ callback, items }) => {
  const [toggle, setToggle] = useState(false)

  const toggleAllItems = (value) => (items) => {
    const data = alterAll(value, items)
    callback(getPriority('sequence')(data))
  }

  const onSelect = (item) => {
    const isFound = items.find((f) => f.sequence === item.sequence)
    if (isFound && isFound.active) {
      const newData = alter(false, item.sequence, items)
      callback(getPriority('sequence')(newData))
    } else {
      const newData = alter(true, item.sequence, items)
      callback(getPriority('sequence')(newData))
    }
  }

  const toggleSelectAll = (value) => setToggle(!value)

  useEffect(() => {
    if (toggle) {
      toggleAllItems(true)
      return
    }
    toggleAllItems(true)
  }, [toggle])

  const renderItems = () => {
    if (!items || items.length <= 0) return null
    return items.map((item) => <MultiItem key={item.id} item={item} onSelect={onSelect} />)
  }

  return (
    <MultiExpander isDefaultExpanded={true} label="Select">
      <MultiContainer>
        <Button config={config} variant="OUTLINE" onClick={() => toggleSelectAll(toggle)}>
          <MultiCheck isActive={toggle} label="Select all" />
        </Button>
        <MultiGroup>{renderItems()}</MultiGroup>
      </MultiContainer>
    </MultiExpander>
  )
}

MultiSelect.defaultProps = {
  items: [],
  callback: () => {},
}

export { MultiSelect }
