import React from 'react'
import Highcharts from 'highcharts/highstock'
import PieChart from 'highcharts-react-official'

const options = {
  chart: {
    type: 'pie',
    // plotBackgroundColor: '#F8F8F8',
    // borderWidth: 0,
    // plotBorderWidth: 0,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: false,
    },
  },
  series: [
    {
      name: '',
      color: '#006600',
      lineWidth: 1,
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 3,
        states: {
          hover: {
            enabled: true,
            lineWidth: 1,
          },
        },
      },
      data: [
        {
          y: 10,
          sliced: true,
        },
        {
          y: 10,
          sliced: true,
        },
        {
          y: 0,
          sliced: true,
        },
      ],
    },
  ],
}

import styled from 'styled-components'
import { prop, head, pipe, toUpper } from 'ramda'

const PieContainer = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
  .highcharts-background {
    fill: #f8f8f8;
  }
`

const Chart = ({ values = [] }) => {
  const getFirstSeries = pipe(prop('series'), head)(options)

  // create array
  const dataStructure = [
    {
      category: 'OTHER',
      y: 17,
      sliced: true,
    },
  ]
  const getData = (values) => {
    const filterData = values.filter(
      (item) => prop('category')(item) && toUpper(prop('category')(item)) === 'OTHER'
    )
    return filterData.reduce((prev, curr) => {
      const newItem = {
        y: curr.value || 0,
        sliced: true,
      }
      return [...prev, newItem]
    }, [])
  }

  // const getData = (values) => {
  //   return values.reduce((prev, curr) => {
  //     const newItem = {
  //       y: curr.value || 0,
  //       sliced: true,
  //     };
  //     return [...prev, newItem];
  //   }, []);
  // };

  const data = getData(values)

  const newOptions = {
    ...options,
    series: {
      ...getFirstSeries,
      data: data,
    },
  }
  return (
    <PieContainer>
      <PieChart highcharts={Highcharts} options={newOptions} />
    </PieContainer>
  )
}

export { Chart }
