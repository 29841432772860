import { values, prop } from 'ramda'
import { getExpenseTotal } from './advanced'
// Setup

// b.value = 10.5
// timeframe = DAILY which is 1

// Calculation 10.5 / 31) = 0.3387

// Setup
// Continued on so 0.3387 * 1

// Soon to be depricated
const getMultiplier = (frequency) =>
  ({
    MONTHLY: 12,
    FORTNIGHTLY: 26,
    WEEKLY: 52,
    DAILY: 365,
    YEARLY: 1,
    ONCE: 1,
    QUARTERLY: 4,
  }[frequency ? frequency.toUpperCase() : 'YEARLY'])

const getCapitalise = (string) => string.charAt(0).toUpperCase() + string.slice(1)

const getReverseMultiplier = (frequency) =>
  ({
    DAILY: 1,
    WEEKLY: 7,
    FORTNIGHTLY: 14,
    MONTHLY: 31,
    QUARTERLY: 91,
    YEARLY: 365,
    ONCE: 365,
  }[frequency] || 365)

const getVariant = (type) =>
  ({
    asIncome: 'asIncome',
    asExpense: 'asExpense',
  }[(type && type.toUpperCase()) || 'asIncome'])

const getShorthandFrequency = (type) =>
  ({
    YEARLY: 'Y',
    QUARTERLY: 'Q',
    MONTHLY: 'M',
    FORTNIGHTLY: 'F',
    WEEKLY: 'W',
    DAILY: 'D',
    ONCE: 'O',
  }[(type && type.toUpperCase()) || 'ONCE'])

const getTimeframe = (type) =>
  ({
    YEARLY: '/yr',
    QUARTERLY: '/qt',
    MONTHLY: '/mo',
    FORTNIGHTLY: '/fn',
    WEEKLY: '/wk',
    DAILY: '/day',
    ONCE: '/once',
    NONE: '',
  }[(type && type.toUpperCase()) || 'NONE'])

const getAmount = (amount) => {
  const remaining = amount && amount > 0 ? Number(amount).toFixed(2) : '0'
  return `$${getWithCommas(remaining)}`
}

const getExpenseInflation = (value, inflation) => {
  if (!inflation || inflation === '' || Number(inflation) < 0) return 0
  return value * Number(inflation)
}

const getCategoryExpenseTotals = ({ items, timeframe, inflation }) => {
  console.log({ items })
  const result = items.reduce((prev, curr) => {
    // console.log('what is curr Philip ----', curr.value)
    const currVal = getExpenseTotal({
      value: curr.value,
      frequency: curr.frequency,
      timeframe,
      inflation,
    })
    // what does getExpenseTotal do?
    // console.log('Philip --- :', items, timeframe, currVal)
    const res = parseInt(currVal, 10)
    // what does parseInt do?
    return prev + res
  }, 0)
  return result > 0 ? result : 0
}

const getCost = ({ list, inflation, timeframe }) => {
  // Each list item is an expense
  const items = values(list)
  if (!items || items.length <= 0) return 0

  // This reduce method will return a number
  const result = items.reduce((a, b) => {
    // Example b.frequency = MONTHLY so then we perform Number(31)
    const constructedValueByYear = Number(getReverseMultiplier(b.frequency))

    const currentExpenseValue = prop('value')(b)

    const calculatedInflation = getExpenseInflation(currentExpenseValue, inflation)

    const calculatedWithInflation = currentExpenseValue + calculatedInflation

    const calcultated = (calculatedWithInflation / constructedValueByYear) * getReverseMultiplier(timeframe)

    return a + Number(calcultated)
  }, 0)
  return result > 0 ? result.toFixed(2) : result
}

const sum = (a, b) => {
  return a + b
}

const getCents = (number) => (parseFloat(number) * 100).toString()

const getWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const getBalance = ({ income, incomeTimeframe, balanceTimeframe, inflation, list }) => {
  const derivedGrossIncome = (income * getMultiplier(incomeTimeframe)) / getMultiplier(balanceTimeframe)

  const derivedCost = getCost({
    list: list,
    timeframe: balanceTimeframe,
    inflation: 0,
  })
  return derivedGrossIncome - derivedCost
}

// example data for getGroups
const items = [
  {
    id: 'c7762b1e-0bfb-44c1-ade2-3930d05e2a7c',
    value: 62,
    category: 'OTHER',
    label: 'Telstra',
    start: '',
    end: '',
    frequency: 'MONTHLY',
    sequence: 0,
  },
  {
    id: 'c7762b1e-0bfb-44c1-ade2-3930d05e2acs',
    value: 70,
    category: 'ENTERTAINMENT',
    label: 'Disney',
    start: '',
    end: '',
    frequency: 'MONTHLY',
    sequence: 0,
  },
]

// example data for getGroups
const categories = [
  { id: 'FOOD', label: 'Food', swatch: 'green' },
  {
    id: 'ENTERTAINMENT',
    label: 'Entertainment',
    swatch: 'teal',
  },
]

const getGroups = (categories) => (items) => {
  if (!categories || categories.length <= 0) return []
  if (!items || items.length <= 0) return []

  const result = categories.reduce((prev, curr) => {
    const category = prop('id')(curr)
    const swatch = prop('swatch')(curr)

    // Filter out items by category
    const itemResults = items.filter((f) => f.category && f.category.toUpperCase() === category)

    const totalResults = itemResults.reduce((prev, curr) => {
      return prev + curr.value
    }, 0)

    const newItem = {
      [category]: {
        items: itemResults,
        category: category,
        total: totalResults,
        active: itemResults.length,
        swatch: swatch,
      },
    }
    return {
      ...prev,
      ...newItem,
    }
  }, {})
  return result
}

// what does getExpenseTotal do?

const getTruncatedString = (maxLength) => (value) => {
  if (!value || value === 0) return `${value}`
  const stringifyValue = typeof value === 'number' ? value.toString() : value
  if (stringifyValue && stringifyValue.length >= maxLength) {
    const result = stringifyValue.slice(0, maxLength - 1)
    return result
  }
  return `${value}`
}

export {
  getMultiplier,
  getReverseMultiplier,
  getCost,
  getTruncatedString,
  getCents,
  getShorthandFrequency,
  getCategoryExpenseTotals,
  getTimeframe,
  getGroups,
  getWithCommas,
  getAmount,
  getExpenseTotal,
  getBalance,
  getVariant,
  getCapitalise,
  sum,
}
