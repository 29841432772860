const exampleCoffeeIncomes = {
  '9240604b-ceeb-4d4a-9fc1-32bf9a16759b': {
    id: '9240604b-ceeb-4d4a-9fc1-32bf9a16759b',
    value: 650,
    label: 'Rent',
    category: 'HOUSING',
    frequency: 'WEEKLY',
    sequence: 0,
  },
  'c7762b1e-0bfb-44c1-ade2-3930d05e2a7c': {
    id: 'c7762b1e-0bfb-44c1-ade2-3930d05e2a7c',
    value: 90,
    label: 'Phone Bill',
    category: 'HOUSING',
    start: '',
    end: '',
    frequency: 'MONTHLY',
    sequence: 0,
  },
  '888304f8-7e6d-4f01-a021-48cce24852ab': {
    id: '888304f8-7e6d-4f01-a021-48cce24852ab',
    value: 250,
    category: 'FOOD',
    label: 'Groceries',
    start: '',
    end: '',
    frequency: 'WEEKLY',
    sequence: 0,
  },
}

export { exampleCoffeeIncomes }
