import React, { useContext, useEffect, useState, useRef } from 'react'

import { prop, values, dissoc, includes } from 'ramda'
import { Select } from '@common/Select'
import { Input } from '@common/Input'
import { Toggle } from '@common/Toggle'
import { uuid } from '@utils/uuid'
import { ControlMenu } from '../ControlPanel/ControlMenu'

import { getCost, getCents, getAmount, getTimeframe } from '@utils/calculators'
import { Context, Types } from 'src/state/Provider/State'

import { Search } from '../Search'
import { Controls } from '../ControlPanel/Controls/Controls'
import {
  ControlWrapper,
  ControlContainer,
  ControlContent,
  ControlLabel,
  ControlHeader,
  ControlTitle,
  ControlButton,
  ControlCustom,
  ControlLayout,
  ControlForm,
  ControlFormGroup,
} from '../ControlPanel/ControlPanel/ControlPanel.styled'
// import { format } from 'date-fns/fp';
import { HeaderControl } from '../HeaderControl'
import { timeframeOptions } from '@utils/constants'
import {
  Provider as PanelProvider,
  Context as PanelContext,
  Types as PanelTypes,
} from '../ControlPanel/ControlProvider'
import { RenderCategories } from './RenderCategories'
import { Chart } from '../Chart'
import { alphaNumericSpace, regexRequest } from '@utils/regex'

const selectConfig = {
  layout: {
    marginLeft: '0px',
  },
  container: {
    height: '48px',
  },
}

const toggleConfig = {
  layout: {
    marginTop: '0px',
  },
}

const defaultState = {
  value: '',
  label: '',
  frequency: 'ONCE',
  start: '',
  end: '',
  category: 'OTHER',
  sequence: 0,
}

const searchConfig = {
  input: {
    placeholder: 'Search through your expenses',
  },
}

const config = {
  title: 'Expenses',
  variant: 'asExpense',
  iconName: 'CHEVRON',
}

const ExpenseBuilderFeature = ({ id }) => {
  const [localState, localDispatch] = useContext(Context)
  const [localPanelState, localPanelDispatch] = useContext(PanelContext)
  const [localSearch, setLocalSearch] = useState([])
  const expenses = prop('expenses')(localState)
  const expenseTimeframe = prop('expenseTimeframe')(localState)
  const inflation = prop('inflation')(localState)
  const cost = prop('cost')(localState)
  const paneDidMount = useRef()

  const selectedExpense = prop('selectedExpense')(localState)

  const categories = prop('categories')(localState)
  const { isMenuOpen } = localPanelState

  const [isRepeated, setRepeat] = useState(false)
  const [state, setState] = useState(defaultState)

  const ref = useRef()
  const labelRef = useRef()

  const items = values(expenses)

  useEffect(() => {
    if (!expenses || values(expenses).length <= 0) {
      localDispatch({ type: Types.UPDATE_COST, data: 0 })
      setLocalSearch([])
      return
    }
    const combinedExpenses = getCost({
      list: expenses,
      timeframe: expenseTimeframe,
      inflation,
    })
    const roundedIncomes = combinedExpenses || 0
    localDispatch({ type: Types.UPDATE_COST, data: roundedIncomes })
    setLocalSearch(values(expenses))
  }, [expenses, expenseTimeframe, inflation])

  const validate = () => {
    if (!state.label || state.label.length <= 0) {
      return false
    }
    if (state.value === undefined || state.value === '') {
      return false
    }
    return true
  }

  const hasNoErrors = validate()

  const onRemove = (id) => {
    const removedById = dissoc(id, expenses)

    localDispatch({ type: Types.REMOVE_EXPENSE, data: removedById })
    const localCost = getCost({
      list: expenses,
      timeframe: expenseTimeframe,
      inflation: inflation,
    })
    localDispatch({ type: Types.UPDATE_COST, data: localCost })
  }

  const onChangeTimeframe = ({ id }) => {
    localDispatch({ type: Types.UPDATE_EXPENSE_TIMEFRAME, data: id })
  }

  const handleSearchCallback = (data) => {
    const { payload } = data
    if (!payload || payload.length <= 0) {
      setLocalSearch(values(expenses))
      return
    }
    setLocalSearch(payload)
  }

  const onCancel = () => {
    setState(defaultState)
    localDispatch({ type: Types.SET_SELECTED_EXPENSE, data: '' })
    localPanelDispatch({ type: PanelTypes.SET_MENU_CLOSED })
  }

  const handleMenuCallback = (value) => {
    if (value === 'OPEN') {
      localPanelDispatch({ type: PanelTypes.SET_MENU_OPEN })
    } else {
      onCancel()
    }
  }

  const onChange = (e) => {
    const { value, name } = e.target

    if (name === 'value') {
      const result = regexRequest(value)
      if (result !== '') {
        const num = Number(getCents(result).toFixed(0))
        setState((state) => ({ ...state, [name]: num }))
        return
      }
      setState((state) => ({ ...state, [name]: value }))
      return
    }
    const result = alphaNumericSpace(value)

    setState((state) => ({ ...state, [name]: result }))
  }

  const onChangeFrequency = ({ id }) => {
    setState((state) => ({ ...state, frequency: id }))
  }

  const onChangeCategory = ({ id }) => {
    setState((state) => ({ ...state, category: id }))
  }

  const handleControlCallback = (value) => {
    if (value === 'CREATE') {
      localPanelDispatch({ type: PanelTypes.SET_MENU_OPEN })
    }
  }

  const onUpdate = () => {
    if (state.value === '' || state.label === '' || state.frequency === '') {
      return
    }

    const { label, frequency, sequence, start, end, value, category } = state
    const coercedNumber = Number(value)

    const data = {
      id: selectedExpense,
      value: coercedNumber,
      label,
      start,
      end,
      frequency,
      category,
      sequence,
    }

    localDispatch({ type: Types.UPDATE_EXPENSE, data: data })
    localDispatch({ type: Types.SET_SELECTED_EXPENSE, data: '' })
    localPanelDispatch({ type: PanelTypes.SET_MENU_CLOSED })

    setState(defaultState)
  }

  const onCreate = (e) => {
    e.preventDefault()
    if (state.value === '' || state.label === '' || state.frequency === '') {
      return
    }
    const id = uuid()
    const { label, frequency, sequence, start, end, value, category } = state

    const coercedNumber = Number(value)

    const data = {
      id,
      value: coercedNumber,
      label,
      start,
      end,
      category,
      frequency,
      sequence,
    }

    localDispatch({ type: Types.ADD_EXPENSE, data: data })
    localPanelDispatch({ type: PanelTypes.SET_MENU_CLOSED })
    setState(defaultState)
  }

  const handleRepeat = (value) => {
    if (value === false) {
      setState((state) => ({ ...state, frequency: 'ONCE' }))
    }
    setRepeat(value)
  }

  const onEdit = (selectedExpense) => {
    if (!selectedExpense || selectedExpense.length <= 0) return
    const mapped = items.map((item) => prop('id')(item))
    const exists = includes(selectedExpense)(mapped)
    if (!exists) return
    const findBySelectedId = items.find((t) => t.id === selectedExpense)
    setState((state) => ({
      ...state,
      ...findBySelectedId,
    }))

    localDispatch({ type: Types.SET_SELECTED_EXPENSE, data: selectedExpense })
    localPanelDispatch({ type: PanelTypes.SET_MENU_OPEN })
  }

  const onHandleListCallback = (value) => {
    if (value === 'placeholder') {
      localPanelDispatch({ type: PanelTypes.SET_MENU_OPEN })
    }
  }

  const isChartVisible = false
  const hasSelectedExpense = Boolean(selectedExpense)

  return (
    <>
      <ControlWrapper ref={paneDidMount} isActive={isMenuOpen} height={660} id={id}>
        <ControlContainer className={config.title}>
          <ControlCustom>
            <ControlHeader id="expense-builder-header" isExpense>
              <ControlTitle>{config.title}</ControlTitle>
              <HeaderControl
                amount={getAmount(cost)}
                variant={config.variant}
                isNegative={config.variant === 'asExpense' && cost > 0}
                timeframe={getTimeframe(expenseTimeframe)}
              />
              <Controls callback={handleControlCallback} />
            </ControlHeader>
            {isMenuOpen && (
              <ControlMenu
                title={hasSelectedExpense ? 'Edit Expense' : 'Add Expense'}
                isActive
                callback={handleMenuCallback}
              >
                <ControlLayout>
                  <ControlFormGroup>
                    <ControlForm>
                      <ControlLabel htmlFor="label">Title</ControlLabel>
                      <Input
                        name="label"
                        id="label"
                        ref={labelRef}
                        maxLength="20"
                        variant="TERTIARY"
                        placeholder="Rent Payment..."
                        value={state.label}
                        onChange={onChange}
                      />
                    </ControlForm>
                    <ControlForm>
                      <ControlLabel htmlFor="value">Amount</ControlLabel>
                      <Input
                        type="text"
                        name="value"
                        variant="TERTIARY"
                        id="value"
                        maxLength="10"
                        min={0}
                        ref={ref}
                        value={state.value}
                        onChange={onChange}
                        placeholder="150"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                      />
                    </ControlForm>
                    <ControlForm>
                      <Select
                        label="Category"
                        config={selectConfig}
                        defaultValue={state.category}
                        callback={onChangeCategory}
                        options={values(categories)}
                      />
                    </ControlForm>
                    <ControlForm mt={8}>
                      <ControlLabel htmlFor="value">Repeat</ControlLabel>
                      <Toggle
                        isActive={Boolean(isRepeated)}
                        onChange={(value) => handleRepeat(value)}
                        title={`Is this a repeated expense?`}
                        config={toggleConfig}
                      />
                      {isRepeated && (
                        <Select
                          config={selectConfig}
                          variant="asExpense"
                          iconName="CHEVRON"
                          activeId={state.frequency}
                          callback={onChangeFrequency}
                          options={values(timeframeOptions)}
                        />
                      )}
                    </ControlForm>
                    <ControlForm style={{ marginTop: '16px', flexDirection: 'row' }}>
                      {!selectedExpense || selectedExpense.length <= 0 ? (
                        <>
                          {hasNoErrors && (
                            <ControlButton style={{ margin: 0 }} onClick={onCreate}>
                              <span>Add Expense</span>
                            </ControlButton>
                          )}
                          <ControlButton variant="OUTLINE" onClick={onCancel}>
                            <span>Cancel</span>
                          </ControlButton>
                        </>
                      ) : (
                        <>
                          <ControlButton onClick={onUpdate}>
                            <span>Update Expense</span>
                          </ControlButton>
                          <ControlButton variant="OUTLINE" onClick={onCancel}>
                            <span>Cancel</span>
                          </ControlButton>
                        </>
                      )}
                    </ControlForm>
                  </ControlFormGroup>
                </ControlLayout>
              </ControlMenu>
            )}
            <ControlContent>
              <Select
                config={selectConfig}
                variant={config.variant}
                activeId={expenseTimeframe}
                callback={onChangeTimeframe}
                iconName={config.iconName}
                options={values(timeframeOptions)}
              />

              {/* {values(expenses) && values(expenses).length > 0 && (
                <Search items={values(expenses)} config={searchConfig} callback={handleSearchCallback} />
              )}  */}

              <RenderCategories
                items={values(expenses)}
                categories={values(categories) || []}
                onEdit={onEdit}
                onRemove={onRemove}
                timeframe={expenseTimeframe}
                activeId={selectedExpense}
                inflation={inflation}
                callback={onHandleListCallback}
                variant="asExpense"
                placeholder="Looks like you haven't added an expense yet. Try adding one."
              />
              {values(expenses) && values(expenses).length > 0 && isChartVisible && (
                <Chart values={values(expenses)} />
              )}
            </ControlContent>
          </ControlCustom>
        </ControlContainer>
      </ControlWrapper>
    </>
  )
}

const ExpenseBuilder = (props) => {
  return (
    <PanelProvider>
      <ExpenseBuilderFeature {...props} />
    </PanelProvider>
  )
}

export { ExpenseBuilder }
