import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Grid, Col, Row } from '@common/Grid'
import { Loader } from '@common/Loader'
import { useDebounce } from '@hooks/useDebounce'
import { useKeyboardShortcut } from '@hooks/useKeyboardShortcut'
import { Context, Types } from '../../state/Provider'
import { Modal } from '@common/Modal'
import { Primary, Button } from '@common/Button'
import { Download } from '@common/Download'
import { Overview } from '../Overview'
import { Icon } from '@common/Icon'
import { Text } from '@common/Text'
import { defaultExpenses } from '@data/expenses'
import { exampleExpenses } from '@data/exampleExpenses'
import { exampleCoffeeExpenses } from '@data/exampleCoffeeExpenses'
import { exampleCoffeeIncomes } from '@data/exampleCoffeeIncomes'
import { defaultIncomes } from '@data/incomes'
import { exampleIncomes } from '@data/exampleIncomes'
import { Select } from '@common/Select'
import { loadState, saveState } from '@utils/storage'
import { format } from 'date-fns/fp'
import { values } from 'ramda'
import { useDoubleClick } from '@hooks/useDoubleClick'
import { usePortal as Portal } from '@hooks/usePortal'

import { logEvent } from 'firebase/analytics'
import { analytics } from '@utils/services/flame'

import { getSavedStates } from './utils/getSavedStates'
import { CategoryBuilder } from '../CategoryBuilder'
// import { InflationBuilder } from '../InflationBuilder'
import Coffee from './Coffee'

import {
  Heading,
  IconContainer,
  HeaderControls,
  SettingsButton,
  SaveButton,
  UploadButton,
  ModalGroup,
  ModalFooter,
  HeaderContainer,
  HeaderContent,
  Dot,
  HeaderLogoMobile,
  HeaderLogoDesktop,
  ModalGrid,
  Right,
  ContentGroup,
  ExampleGroup,
  ExampleItem,
  ModalScrollWrapper,
  ModalContent,
} from './Header.styled'

const selectConfig = {
  layout: {
    marginRight: '8px',
    marginTop: '16px',
    minWidth: '180px',
    maxWidth: '200px',
  },
  container: {
    height: '44px',
  },
}

const config = {
  variant: 'asIncome',
}

const validateHasData = (localState) => {
  const { expenses, balance, grossIncome } = localState
  if (values(expenses) && values(expenses).length > 0) return true
  // if (!values(expenses) || !values(expenses).length) return false;
  if (balance && balance > 0) return true
  if (grossIncome && grossIncome > 0) return true
}

const Header = () => {
  const [className, setClassName] = useState('start')
  const [isReset, setReset] = useState(false)
  const [modal, setModal] = useState(false)
  const [activeExampleId, setActiveExample] = useState('')
  const [isSaving, setSaving] = useState(false)
  const [localSavedStates, setLocalSavedStates] = useState(false)
  const [localSavedId, setLocalSavedId] = useState('')
  const [localState, localDispatch] = useContext(Context)
  const prevState = loadState('saved')

  const [toggle, setToggle] = useState(false)

  const onLoadPhilip = () => {
    setActiveExample('philip')
    localDispatch({ type: Types.UPDATE_INCOME_TOTAL, data: 3130 })
    localDispatch({ type: Types.UPDATE_BALANCE_TIMEFRAME, data: 'WEEKLY' })
    localDispatch({ type: Types.UPDATE_INCOME_TIMEFRAME, data: 'WEEKLY' })
    localDispatch({ type: Types.UPDATE_INFLATION, data: '0.0' })
    localDispatch({ type: Types.UPDATE_EXPENSES, data: defaultExpenses })
    localDispatch({ type: Types.UPDATE_GROSS_INCOMES, data: defaultIncomes })
  }

  const [refCallback, elem] = useDoubleClick(onLoadPhilip)
  const [refCallbackDesktop, elem2] = useDoubleClick(onLoadPhilip)
  const [activePanel, setActivePanel] = useState('view')

  const debouncedValue = useDebounce(isSaving, 600)

  // const onReset = () => {
  //   setReset(true)
  //   if (className === 'start') {
  //     setClassName('end')
  //   } else {
  //     setClassName('start')
  //   }
  // }

  const onOpenSettings = () => {
    const prevState = loadState('saved')
    setModal(true)
    setLocalSavedStates(prevState)
  }

  const handleModalCallback = (value) => {
    console.log(value)
    if (value === 'OPENED') {
      setModal(true)
      return
    }
    setModal(false)
  }

  const loadExample = () => {
    setActiveExample('basic')
    logEvent(analytics, 'click_sample')
    localDispatch({ type: Types.UPDATE_INCOME_TOTAL, data: 500 })
    localDispatch({ type: Types.UPDATE_BALANCE_TIMEFRAME, data: 'WEEKLY' })
    localDispatch({ type: Types.UPDATE_INCOME_TIMEFRAME, data: 'WEEKLY' })
    localDispatch({ type: Types.UPDATE_EXPENSES, data: exampleExpenses })
    localDispatch({ type: Types.UPDATE_GROSS_INCOMES, data: exampleIncomes })
  }

  const loadCoffeeExample = () => {
    if (activeExampleId === 'coffee') {
      setActiveExample('')
      onClear()
      return
    }
    setActiveExample('coffee')
    logEvent(analytics, 'click_sample')
    localDispatch({ type: Types.UPDATE_INCOME_TOTAL, data: 1000 })
    localDispatch({ type: Types.UPDATE_BALANCE_TIMEFRAME, data: 'YEARLY' })
    localDispatch({ type: Types.UPDATE_INCOME_TIMEFRAME, data: 'YEARLY' })
    localDispatch({ type: Types.UPDATE_EXPENSES, data: exampleCoffeeExpenses })
    localDispatch({
      type: Types.UPDATE_GROSS_INCOMES,
      data: exampleCoffeeIncomes,
    })
  }

  const onClear = () => {
    localDispatch({ type: Types.UPDATE_INCOME_TOTAL, data: 0 })
    localDispatch({ type: Types.UPDATE_BALANCE_TIMEFRAME, data: 'WEEKLY' })
    localDispatch({ type: Types.UPDATE_INCOME_TIMEFRAME, data: 'WEEKLY' })
    localDispatch({ type: Types.UPDATE_EXPENSES, data: {} })
    localDispatch({ type: Types.UPDATE_GROSS_INCOMES, data: {} })
  }

  const onSave = () => {
    const { expenses, incomes, grossIncome, incomeTimeframe, balanceTimeframe, expenseTimeframe } = localState
    setSaving(true)
    const date = new Date()
    const time = format('yyyy-MM-dd')(date)

    if (!prevState || !localSavedStates || values(localSavedStates).length <= 0) {
      const data = {
        [time]: {
          expenses,
          incomes,
          grossIncome,
          incomeTimeframe,
          balanceTimeframe,
          expenseTimeframe,
        },
      }
      saveState('saved')(data)
      return
    }

    const data = {
      ...prevState,
      [time]: {
        expenses,
        incomes,
        grossIncome,
        incomeTimeframe,
        balanceTimeframe,
        expenseTimeframe,
      },
    }
    saveState('saved')(data)
  }

  const onHandleMenuSelect = (id) => setActivePanel(id)

  const onLoadSave = (time) => {
    if (!prevState || values(prevState) <= 0 || !time.id) return

    setLocalSavedId(time.id)
    setActiveExample('')

    const pluckedState = prevState[time.id]

    // console.log({ pluckedState })

    const {
      expenses,
      incomes,
      grossIncome,
      incomeTimeframe,
      balanceTimeframe,
      expenseTimeframe,
    } = pluckedState

    if (!pluckedState) return

    localDispatch({ type: Types.UPDATE_INCOME_TOTAL, data: grossIncome })
    localDispatch({
      type: Types.UPDATE_EXPENSE_TIMEFRAME,
      data: expenseTimeframe,
    })
    localDispatch({
      type: Types.UPDATE_BALANCE_TIMEFRAME,
      data: balanceTimeframe,
    })
    localDispatch({
      type: Types.UPDATE_INCOME_TIMEFRAME,
      data: incomeTimeframe,
    })
    localDispatch({ type: Types.UPDATE_EXPENSES, data: expenses })
    localDispatch({ type: Types.UPDATE_GROSS_INCOMES, data: incomes })
  }

  const keysPhilip = ['Shift', 'P']
  const handleKeyboardShortcut = useCallback((keys) => {
    onLoadPhilip()
  }, [])

  useKeyboardShortcut(keysPhilip, handleKeyboardShortcut)

  const savedStates = getSavedStates(prevState)

  const hasData = validateHasData(localState)
  useEffect(() => {
    if (!debouncedValue || !isSaving) return
    if (debouncedValue === true) {
      setSaving(false)
      const prevState = loadState('saved')
      setLocalSavedStates(prevState)
    }
  }, [debouncedValue, isSaving])

  const renderDownloadControl = () => {
    return (
      values(localState.expenses) &&
      values(localState.expenses).length > 0 && <Download id="download-content" />
    )
  }

  const renderUploadControl = () => {
    return (
      <UploadButton mb={16} variant="SAVE" onClick={onSave}>
        <Icon name="DOWNLOAD" rotate={180} stroke="rgb(21, 25, 27)" size={26} />
      </UploadButton>
    )
  }

  const renderSettingsControl = () => {
    return (
      <SettingsButton isActive={modal} onClick={onOpenSettings}>
        <Icon name="SETTINGS" stroke="rgb(21, 25, 27)" size={26} />
      </SettingsButton>
    )
  }

  const renderSaveControl = () => {
    if ((isSaving && debouncedValue !== isSaving) || (!isSaving && debouncedValue !== isSaving)) {
      return (
        <>
          <SaveButton mb={16} variant="SAVE" onClick={() => console.log()}>
            <Loader position="relative" size={28} style={{ marginTop: '3px' }} />
          </SaveButton>
        </>
      )
    }
    if (!isSaving) {
      return (
        <>
          <SaveButton mb={16} variant="SAVE" onClick={onSave}>
            {localSavedStates && values(localSavedStates).length > 0 && <Dot />}
            <Icon name="SAVE" stroke="rgb(21, 25, 27)" size={26} />
          </SaveButton>
        </>
      )
    } else {
      return (
        <SaveButton mb={16} variant="SAVE" onClick={onSave}>
          <Icon name="SAVE" stroke="rgb(21, 25, 27)" size={26} />
        </SaveButton>
      )
    }
  }

  return (
    <>
      <Overview />
      <HeaderContainer className="HeaderContainer">
        <Grid marginX={12}>
          <Row>
            <Col>
              <HeaderContent>
                <HeaderLogoDesktop isActive={toggle} ref={refCallbackDesktop}>
                  <span className="deselect">paydai</span>
                  {activeExampleId === 'philip' && <Icon ml={5} name="CHECKMARK" size={20} />}
                </HeaderLogoDesktop>
                <HeaderLogoMobile isActive={toggle} className={`HeaderLogoMobile`} ref={refCallback}>
                  pd
                </HeaderLogoMobile>

                <HeaderControls>
                  {renderUploadControl()}
                  {renderDownloadControl()}
                  {renderSaveControl()}
                  {renderSettingsControl()}
                </HeaderControls>
              </HeaderContent>

              {modal && (
                <Modal title="Settings" callback={handleModalCallback}>
                  <ModalGrid>
                    <Right>
                      <ModalScrollWrapper>
                        <ModalContent>
                          <ModalGroup>
                            <ContentGroup mt={8}>
                              <Heading>Getting started</Heading>
                              <Text as="p" weight={300}>
                                Want to see what this app can do? Click any of the buttons below to see some
                                pre-filled in data. You can edit or delete anything you like. Enjoy!
                              </Text>
                              <ExampleGroup>
                                <ExampleItem
                                  isActive={activeExampleId === 'coffee'}
                                  onClick={loadCoffeeExample}
                                >
                                  <IconContainer>
                                    {activeExampleId === 'coffee' && <Icon name="CHECKMARK" size={20} />}
                                  </IconContainer>
                                  <Coffee size={32} />
                                  <span>Coffee Example</span>
                                </ExampleItem>
                              </ExampleGroup>
                            </ContentGroup>

                            {/* <ContentGroup mt={8}>
                              <Heading>Tools</Heading>
                              <Text as="p" weight={300}>
                                Want to see what your expenses will look like
                                with inflation applied? Use the tool below to
                                apply an inflation rate to your expenses. I hope
                                you like it!
                              </Text>
                              <InflationBuilder />
                            </ContentGroup> */}

                            <ContentGroup mt={8}>
                              <CategoryBuilder />
                            </ContentGroup>

                            <ContentGroup mt={8}>
                              <Heading>Your saved data</Heading>
                              {localSavedStates && values(localSavedStates).length > 0 ? (
                                <>
                                  <Text as="p" weight={300}>
                                    Want to load a previous saved state of what you've been working so hard
                                    on? Go ahead and select from the list provided below. Each save is
                                    recorded by date.
                                  </Text>

                                  <Select
                                    config={selectConfig}
                                    variant={config.variant}
                                    activeId={
                                      values(localSavedStates).find((t) => t.id === localSavedId)
                                        ? localSavedStates.find((t) => t.id === localSavedId).id
                                        : ''
                                    }
                                    iconName="CHEVRON"
                                    placeholder="Load Saved Data"
                                    callback={onLoadSave}
                                    options={savedStates}
                                  />
                                </>
                              ) : (
                                <Text as="p" weight={300}>
                                  Looks like you haven't saved something yet. Use the{' '}
                                  <strong>Save button</strong> in the header of the app in order to save you
                                  work.
                                </Text>
                              )}
                            </ContentGroup>

                            <ContentGroup>
                              <Heading>Delete data</Heading>
                              <Text as="p" weight={300}>
                                At times you may need to start over from scratch. We believe in second
                                chances, so feel free to reset the application by pressing the button below.
                              </Text>

                              <Button
                                onClick={onClear}
                                variant="PRIMARY"
                                mt={16}
                                style={{ maxWidth: '150px' }}
                              >
                                Reset
                                <IconContainer>
                                  <Icon name="TRASH" stroke="white" size={20} />
                                </IconContainer>
                              </Button>
                            </ContentGroup>
                          </ModalGroup>
                        </ModalContent>
                      </ModalScrollWrapper>
                    </Right>
                  </ModalGrid>
                  <ModalFooter />
                </Modal>
              )}
            </Col>
          </Row>
        </Grid>
      </HeaderContainer>
      {hasData && (
        <Portal selector="#save-control">
          <Button
            mb={16}
            variant="SAVE"
            style={{ minWidth: '150px', margin: 0, marginLeft: 'auto' }}
            onClick={onSave}
          >
            <span>Save</span>
            <IconContainer>
              <Icon name="SAVE" stroke="rgb(21, 25, 27)" size={26} />
            </IconContainer>
          </Button>
        </Portal>
      )}
    </>
  )
}

export { Header }
