import styled, { css } from 'styled-components'

const CreditorContainer = styled.div`
  padding: 8px;
  height: 100%;
`

const CreditorList = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 22px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  background: white;
  border-radius: 8px;
  padding: 8px 8px;
  height: 100%;
`

const Role = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-top: 16px;
`

const Features = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Name = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-top: 16px;
`

const Image = styled.img`
  position: relative;
  object-fit: fill;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
`

const Link = styled.a`
  border-radius: 6px;
  justify-content: center;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
  color: black;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  margin-top: auto;
  border: 1px solid #d8d8d8;
  max-width: 200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    background: #e0e0e0;
  }
`

export { CreditorContainer, CreditorList, Role, Features, Name, Image, Link }
