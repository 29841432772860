import React, { useContext, useRef } from 'react';

import PropTypes from 'prop-types';
import { ControlMenu } from '../ControlMenu';
import { Controls } from '../Controls';
import {
  ControlWrapper,
  ControlContainer,
  ControlContent,
  ControlHeader,
  ControlTitle,
  ControlCustom,
} from './ControlPanel.styled';
import {
  Types as ControlTypes,
  Context as ControlContext,
} from '../ControlProvider';
import { useDimensions } from '@hooks/useDimensions';

const ControlPanel = ({
  children,
  title,
  id,
  callback,
  controls,
  menu,
  header,
  shouldUpdate,
  ...props
}) => {
  const [localState, localDispatch] = useContext(ControlContext);
  const { isMenuOpen } = localState;
  const controlRef = useRef();

  const handleControlCallback = (value) => {
    if (value === 'CREATE') {
      localDispatch({ type: ControlTypes.SET_MENU_OPEN });
    }
  };

  const handleMenuCallback = (value) => {
    if (value === 'OPEN') {
      callback(ControlTypes.SET_MENU_OPEN);
      localDispatch({ type: ControlTypes.SET_MENU_OPEN });
    } else {
      callback(ControlTypes.SET_MENU_CLOSED);
      localDispatch({ type: ControlTypes.SET_MENU_CLOSED });
    }
  };

  const { height: dimensionHeight } = useDimensions(controlRef);

  const getHeight = ({ dimensionHeight }) => {
    const setByDefaultHeight = 400;
    const setByDimensions =
      dimensionHeight && dimensionHeight > 400 ? dimensionHeight : 500;

    return setByDimensions || setByDefaultHeight;
  };

  const height = getHeight({ dimensionHeight });

  return (
    <ControlWrapper>
      <ControlContainer
        className={title}
        id={id}
        height={height}
        isActive={isMenuOpen}
        {...props}
      >
        <ControlCustom>
          <ControlHeader>
            {title && <ControlTitle>{title}</ControlTitle>}
            {header && header}
            <Controls callback={handleControlCallback} />
          </ControlHeader>
          <ControlMenu
            height={isMenuOpen ? '900' : '500'}
            title={'Additional Income'}
            isActive={isMenuOpen}
            callback={handleMenuCallback}
          >
            {menu}
          </ControlMenu>
          {children && (
            <ControlContent ref={controlRef} className="ControlContent">
              {children}
            </ControlContent>
          )}
        </ControlCustom>
      </ControlContainer>
    </ControlWrapper>
  );
};

ControlPanel.propTypes = {
  config: PropTypes.object,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  controls: PropTypes.object,
  menu: PropTypes.node,
  callback: PropTypes.func,
  shouldUpdate: PropTypes.bool,
};

ControlPanel.defaultProps = {
  config: {},
  title: 'Control Panel',
  callback: () => {},
  id: '',
  shouldUpdate: false,
  menu: <></>,
};

export { ControlPanel };
