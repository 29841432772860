import React from 'react';
import styled, { css } from 'styled-components';

const HeaderOccurrence = styled.span`
  width: 0px;
  max-width: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-weight: 600;
  user-select: none;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isActive &&
    css`
      font-size: 11px;
      left: calc(100% - 10px);
      width: 50px;
      opacity: 1;
      max-width: 50px;
    `}
`;

const HeaderControlContainer = styled.div.attrs({
  className: 'HeaderControlContainer',
})`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  height: 48px;
  max-width: 200px;
  cursor: pointer;
  background: #81d29f;
  z-index: 80;
`;

const Text = styled.span`
  color: white;
  font-weight: 600;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #4fbe79;
  border: 1px solid #2c8c51;
  padding: 4px 8px;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${(props) =>
    props.isNegative &&
    css`
      background: #ff6767;
      border: 1px solid #b20000;
    `}
`;

const HeaderTitle = styled.span`
  width: 0px;
  max-width: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  user-select: none;
  transform: translateY(-50%);
  color: white;
  font-weight: 600;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  ${(props) =>
    props.isActive &&
    css`
      font-size: 11px;
      left: -66px;
      width: 60px;
      max-width: 60px;
    `}
`;

const HeaderControl = ({
  amount = 0,
  isDisabled,
  isNegative,
  timeframe,
  title,
}) => {
  const result = (
    <HeaderControlContainer>
      <Text isNegative={isNegative}>
        <HeaderTitle isActive>{title}</HeaderTitle>
        <span>{amount}</span>
        <HeaderOccurrence isActive>{timeframe}</HeaderOccurrence>
      </Text>
    </HeaderControlContainer>
  );

  return isDisabled ? null : result;
};

HeaderControl.defaultProps = {
  isDisabled: false,
};
export { HeaderControl };
