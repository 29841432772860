/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

const IconPath = (props) => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <path
        d="M13.408 3.88925L13.4112 3.89593L13.4145 3.90255C13.6434 4.35911 14.1229 4.72256 14.7039 4.72256H16.2592C15.8997 4.91358 15.6597 5.28668 15.6249 5.6932L14.4744 16.3513C14.3407 17.4887 13.4588 18.2687 12.4884 18.27C12.4876 18.27 12.4868 18.27 12.4859 18.27L9.99845 18.2516L9.99845 18.2515L9.98734 18.2516L7.49984 18.27C7.49901 18.27 7.49818 18.27 7.49735 18.27C6.52697 18.2687 5.64512 17.4887 5.51139 16.3513L4.3609 5.69321C4.32609 5.28668 4.08613 4.91358 3.72655 4.72256H5.29908C5.85296 4.72256 6.35445 4.40217 6.59616 3.8868C7.20596 2.61042 8.37229 1.81602 9.66719 1.75H9.99289H10.0101H10.0273H10.3525C11.615 1.81609 12.7988 2.61199 13.408 3.88925Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M10.6429 4.4502C10.8632 4.4502 11.0282 4.55346 11.1193 4.68797L9.03431 4.70214C9.12646 4.56654 9.29272 4.45837 9.51933 4.4502H10.6429ZM11.451 5.43573L8.73441 5.45419C8.44212 5.45419 8.21861 5.23263 8.20142 4.95569L11.451 5.43573Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <line
        x1="11.6517"
        y1="9.85059"
        x2="11.6517"
        y2="13.751"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="8.32129"
        y1="9.85059"
        x2="8.32129"
        y2="13.751"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
