import styled, { css } from 'styled-components'
import { mbFn, mtFn } from '@utils/styles'

const Tertiary = styled.input`
  outline: none;
  border-radius: 6px;
  background: white;
  /* background: #38363a; */
  font-size: 16px;
  padding: 12px;
  width: 100%;
  appearance: none;
  box-sizing: border-box;
  border: none;
  /* box-shadow: inset 0px -2px 0px 0px #4f4e54; */
  border: 2px solid #e0e0e0;
  color: black;
  ${mbFn};
  ${mtFn}
  transition: all .2s ease-in-out;
  &:focus {
    background: #38363a;
    background: #e0e0e0;
    outline: none;
  }
  &::active {
    background: #38363a;
    box-shadow: inset 0px -2px 0px 0px #4f4e54;
  }
  &::focus {
    background: #38363a;
    box-shadow: inset 0px -2px 0px 0px #4f4e54;
  }
`

export { Tertiary }
