import styled from 'styled-components';

const ControlsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ControlsButton = styled.button`
  outline: none;
  position: absolute;
  width: 32px;
  height: 32px;
  right: 12px;
  top: 12px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  &:focus {
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  &:hover {
    cursor: pointer;
    background: #4f4e54;
    border: 1px solid #4f4e54;
  }
`;

export { ControlsGroup, ControlsButton };
