import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { Icon } from '@common/Icon';
import {
  Text,
  ModalContainer,
  ModalContent,
  CloseButton,
  ModalScrollWrapper,
  Overlay,
  ModalHeader,
} from './Modal.styled';

const Modal = ({ children, title, callback, hasLocalScroll }) => {
  const refClickOutside = useRef();

  useOnClickOutside(refClickOutside, () => {
    callback('CLOSED');
  });

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      callback('CLOSED');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
    // eslint-disable-next-line
  }, []);

  const renderWrapper = (
    <>
      <ModalContainer ref={refClickOutside} level={9}>
        <ModalHeader>
          <Text
            size="20"
            lineHeight="24px"
            fontWeight={600}
            style={{ textAlign: 'left', color: 'white' }}
          >
            {title}
          </Text>
          <CloseButton onClick={() => callback('CLOSED')}>
            <Icon name="CLOSE" size={20} stroke="#9e9ba6" />
          </CloseButton>
        </ModalHeader>
        {hasLocalScroll ? (
          <ModalScrollWrapper>
            <ModalContent>{children}</ModalContent>
          </ModalScrollWrapper>
        ) : (
          <>{children}</>
        )}
      </ModalContainer>
      <Overlay level={1} isActive />
    </>
  );

  return renderWrapper;
};

Modal.defaultProps = {
  hasLocalScroll: false,
  callback: () => {},
};

export { Modal };
