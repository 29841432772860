import React from 'react'

import {
  getShorthandFrequency,
  getCapitalise,
  // getCategoryExpenseTotals,
  getTruncatedString,
  getTimeframe,
} from '@utils/calculators'
import { getExpenseTotal } from '@utils/calculators/advanced'
import {
  ListGroup,
  ListItem,
  Title,
  ListItemWrapper,
  TotalGroup,
  CategoryName,
  CategoryTotalContainer,
  CategoryWrapper,
  Push,
  Repeat,
} from './Categories.styled'
import { MultiSelect } from '@common/MultiSelect'
import { ListControls } from './ListControls'
import { Placeholder } from '@common/Placeholder'
import { ListTotal } from '../../common/List/List.styled'

// const Total = ({ value, frequency, timeframe, variant, inflation }) => {
//   const categoryTotal = getExpenseTotal({
//     value,
//     frequency,
//     timeframe,
//     inflation,
//   })

//   return (
//     <TotalGroup className={variant}>
//       {variant === 'asExpense' ? `-` : `+`}
//       {categoryTotal}
//     </TotalGroup>
//   )
// }

// parseInt(
const CategoryTotal = ({ total, timeframe, title }) => {
  if (!title || !title.length) return null

  return (
    <CategoryTotalContainer>
      <span className="categoryWrapper">
        <span className="titleCase"></span>
        <span className="lowerCase">Total:</span>
      </span>
      <span className="categoryTotal">
        {` `}${total}
        <span className="categoryTimeframe">{getTimeframe(timeframe)}</span>
      </span>
    </CategoryTotalContainer>
  )
}

const Categories = ({
  items,
  onRemove,
  onEdit,
  callback,
  timeframe,
  inflation,
  activeId,
  variant,
  categorySwatch,
  categoryName,
  placeholder,
}) => {
  if (!items || !items.length) {
    return <Placeholder onClick={() => callback('placeholder')}>{placeholder}</Placeholder>
  }

  // console.log('items ::', items)
  // console.log('timeframe ::', timeframe)
  const createTotals = (items) => {
    // iterate over the items and create a total for each category based on the timeframe and the frequency
    // return an object with the category name and the total
    const result = items.reduce((prev, curr) => {
      const currVal = getExpenseTotal({
        value: curr.value,
        frequency: curr.frequency,
        timeframe,
        inflation: 0,
      })
      console.log('PHILIP >>', { value: curr.value, frequency: curr.frequency, timeframe, inflation: 0 })
      const newItem = {
        total: parseInt(currVal, 10),
        id: curr.id,
        label: curr.label,
      }
      return [...prev, newItem]
    }, [])

    const getTotal = result.reduce((prev, curr) => {
      return prev + curr.total
    }, 0)

    // return result
    return getTotal
  }

  // console.log(`createTotals(items) ${timeframe} ${categoryName}:`, createTotals(items))

  const renderItems = items.map((item) => (
    <ListItemWrapper key={item.id}>
      <ListItem className={`ListItem ${variant}`} isSelected={item.id === activeId}>
        <Title>{item.label}</Title>
        <Push>
          <Repeat asMobile type={item.frequency}>
            {getShorthandFrequency(item.frequency)}
          </Repeat>
          <Repeat asDesktop type={item.frequency}>
            {item.frequency}
          </Repeat>
          <ListTotal>${getTruncatedString(8)(item.value)}</ListTotal>
          <ListControls activeItem={item} onEdit={onEdit} onRemove={onRemove} callback={callback} />
        </Push>
      </ListItem>
    </ListItemWrapper>
  ))

  return (
    <ListGroup maxHeight={2000}>
      {categoryName && (
        <CategoryName swatch={categorySwatch}>
          <span>{getCapitalise(categoryName.toLowerCase())}</span>
        </CategoryName>
      )}
      <CategoryWrapper swatch={categorySwatch}>
        {renderItems}
        <CategoryTotal total={createTotals(items)} title={categoryName} />
      </CategoryWrapper>
    </ListGroup>
  )
}

Categories.defaultProps = {
  categorySwatch: '',
  categoryName: '',
  categoryTotal: 0,
  onRemove: () => {},
  onEdit: () => {},
  callback: () => {},
  variant: 'asExpense',
  categorySwatch: '',
  categoryName: '',
  categoryTotal: 0,
  timeframe: '',
  inflation: '0',
  placeholder: '',
  activeId: '-1',
  items: [],
}

export { Categories }
