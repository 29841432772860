import React from 'react'
import { values } from 'ramda'
import { getGroups } from '@utils/calculators'
import { Categories } from '../Categories'

import {
  getExpenseTotal,
  getShorthandFrequency,
  getCapitalise,
  getCategoryExpenseTotals,
  getTruncatedString,
  getTimeframe,
} from '@utils/calculators'

const RenderCategories = ({ items, timeframe, categories, ...props }) => {
  const categoryGroups = values(getGroups(categories)(items))

  // console.log({ categoryGroups })

  return categoryGroups.map((group) => {
    if (!group.active) return null

    // const categoryTotal = getExpenseTotal({
    //   value,
    //   frequency,
    //   timeframe,
    //   inflation,
    // })

    console.log(group.category)

    const total = getCategoryExpenseTotals({
      items: group.items,
      timeframe,
      inflation: 0,
    })

    console.log(`${group.category} - ${timeframe}`, total)

    return (
      <React.Fragment key={group.category}>
        <Categories
          items={group.items}
          categoryName={group.category}
          timeframe={timeframe}
          categorySwatch={group.swatch}
          {...props}
        />
      </React.Fragment>
    )
  })
}

RenderCategories.defaultProps = {
  items: [],
  categories: [],
}

export { RenderCategories }
