import Types from './Types';

const defaultCategories = {
  FOOD: {
    value: 'FOOD',
    id: 'FOOD',
    index: 0,
    label: 'Food',
    swatch: '#ece6cb',
  },
  ENTERTAINMENT: {
    value: 'ENTERTAINMENT',
    id: 'ENTERTAINMENT',
    index: 1,
    label: 'Entertainment',
    swatch: '#6ce0bd',
  },
  TRANSPORTATION: {
    value: 'TRANSPORTATION',
    id: 'TRANSPORTATION',
    index: 2,
    label: 'Transportation',
    swatch: '#58c8e9',
  },
  HEALTH: {
    value: 'HEALTH',
    id: 'HEALTH',
    index: 3,
    label: 'Health / Wellness',
    swatch: '#ffc4c4',
  },
  UTILITIES: {
    value: 'UTILITIES',
    id: 'UTILITIES',
    index: 4,
    label: 'Utilities',
    swatch: '#f0d9ac',
  },
  HOUSING: {
    value: 'HOUSING',
    id: 'HOUSING',
    index: 5,
    label: 'Housing',
    swatch: '#aca7c6',
  },
  INSURANCE: {
    value: 'INSURANCE',
    id: 'INSURANCE',
    index: 6,
    label: 'Insurance',
    swatch: '#89b070',
  },
  MEDICAL: {
    value: 'MEDICAL',
    id: 'MEDICAL',
    index: 7,
    label: 'Medical / Healthcare',
    swatch: '#b4dbd3',
  },
  TRAVEL: {
    value: 'TRAVEL',
    id: 'TRAVEL',
    index: 8,
    label: 'TRAVEL',
    swatch: '#b4dbbd',
  },
  BUSINESS: {
    value: 'BUSINESS',
    id: 'BUSINESS',
    index: 9,
    label: 'Business Expenses',
    swatch: '#fd7d7d',
  },
  OTHER: {
    value: 'OTHER',
    id: 'OTHER',
    index: 10,
    label: 'Other',
    swatch: '#e8ebf2',
  },
};

const initialState = {
  expenses: {},
  incomes: {},
  grossIncome: '',
  balance: 0,
  cost: 0,
  categories: {
    ...defaultCategories,
  },
  selectedExpense: '',
  selectedIncome: '',
  enableTools: false,
  inflation: '0.0',
  incomeTimeframe: 'YEARLY',
  balanceTimeframe: 'YEARLY',
  expenseTimeframe: 'YEARLY',
};

const Reducer = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case Types.ADD_EXPENSE:
      return {
        ...state,
        expenses: {
          ...state.expenses,
          [data.id]: data,
        },
      };
    case Types.ADD_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          [data.id]: data,
        },
      };
    case Types.UPDATE_EXPENSES:
      return {
        ...state,
        expenses: data,
      };
    case Types.UPDATE_INFLATION:
      return {
        ...state,
        inflation: data,
      };
    case Types.REMOVE_EXPENSE:
      return {
        ...state,
        expenses: data,
      };
    case Types.UPDATE_EXPENSE:
      return {
        ...state,
        expenses: {
          ...state.expenses,
          [data.id]: data,
        },
      };
    case Types.ADD_GROSS_INCOME:
      return {
        ...state,
        incomes: {
          ...state.incomes,
          [data.id]: data,
        },
      };
    case Types.UPDATE_GROSS_INCOMES:
      return {
        ...state,
        incomes: data,
      };
    case Types.REMOVE_GROSS_INCOME:
      return {
        ...state,
        incomes: data,
      };
    case Types.REMOVE_CATEGORY:
      return {
        ...state,
        categories: data,
      };
    case Types.UPDATE_GROSS_INCOME:
      return {
        ...state,
        incomes: {
          ...state.incomes,
          [data.id]: data,
        },
      };
    case Types.UPDATE_INCOME_TOTAL:
      return {
        ...state,
        grossIncome: data,
      };
    case Types.UPDATE_INCOME_TIMEFRAME:
      return {
        ...state,
        incomeTimeframe: data,
      };
    case Types.UPDATE_BALANCE:
      return {
        ...state,
        balance: data,
      };
    case Types.SET_DEV_TOOLS:
      return {
        ...state,
        enableTools: data,
      };
    case Types.UPDATE_BALANCE_TIMEFRAME:
      return {
        ...state,
        balanceTimeframe: data,
      };
    case Types.UPDATE_EXPENSE_TIMEFRAME:
      return {
        ...state,
        expenseTimeframe: data,
      };
    case Types.SET_SELECTED_EXPENSE:
      return {
        ...state,
        selectedExpense: data,
      };
    case Types.SET_SELECTED_INCOME:
      return {
        ...state,
        selectedIncome: data,
      };
    case Types.UPDATE_COST:
      return {
        ...state,
        cost: data,
      };
    default:
      return state;
  }
};

export { initialState };

export default Reducer;
