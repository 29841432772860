const firebaseConfig = {
  apiKey: 'AIzaSyBezPPzHjcgcxlnI5RFckGIlwnTxJI2-eM',
  authDomain: 'paydai.firebaseapp.com',
  projectId: 'paydai',
  storageBucket: 'paydai.appspot.com',
  messagingSenderId: '621594824631',
  appId: '1:621594824631:web:b3371fd7403a6ce5402232',
  measurementId: 'G-839MTNKVH1',
};

export { firebaseConfig };
