import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@common/Icon'
import { prop } from 'ramda'

import ClickOutside from '@hooks/useClickOutside'

const Controls = styled.div`
  position: relative;
  margin-left: auto;
`

const Remove = styled.button`
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  padding: 8px;
  background: red;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`

const Edit = styled.button`
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  padding: 8px;
  background: #e0e0e0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`

const ControlsButton = styled.button`
  display: flex;
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  background: white;
  flex-direction: row;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  border: 1px solid #4b5266;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background: #e0e0e0;
  }
`

const ControlsWrapper = styled.div`
  flex-direction: row;
  align-items: center;
  padding: 8px;
  display: flex;
  border-radius: 4px;
  width: 110px;
  background: #3e3e3e;
  position: absolute;
  top: -60px;
  z-index: 1;
  right: calc(-50% + -16px);
  transform: translateX(0%);
  box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 7px 0 7px;
    border-color: #3e3e3e transparent transparent transparent;
  }
`

const ListControls = ({ onEdit = () => {}, onRemove = () => {}, activeItem = {} }) => {
  const [state, setState] = useState(false)
  const id = prop('id')(activeItem)

  const [manageOutside, setManageOutside] = useState(false)

  const handleClose = (e) => {
    if (manageOutside === true && state === true) {
      setManageOutside(false)
      setState(false)
      return
    }
    // callback();
    setState(false)
    e.stopPropagation()
    return
  }

  const onOpen = (e) => {
    e.stopPropagation()

    if (state === true) {
      setState(false)
      setManageOutside(true)
      return
    }
    setState(true)
    setManageOutside(true)
  }

  const onLocalEdit = (id) => {
    setState(false)
    onEdit(id)
  }

  const onLocalRemove = (id) => {
    setState(false)
    onRemove(id)
  }

  const renderMenu = (
    <ClickOutside callback={handleClose}>
      <ControlsWrapper>
        <Edit onClick={() => onLocalEdit(id)}>
          <Icon name="EDIT" fill="black" size={20} />
        </Edit>
        <Remove onClick={() => onLocalRemove(id)}>
          <Icon name="TRASH" stroke="white" size={20} />
        </Remove>
      </ControlsWrapper>
    </ClickOutside>
  )

  return (
    <Controls>
      {state && renderMenu}
      <ControlsButton onClick={onOpen}>
        <Icon name="DOTS" fill="#4b5266" size={22} />
      </ControlsButton>
    </Controls>
  )
}

export { ListControls }
