const exampleCoffeeExpenses = {
  '9240604b-ceeb-4d4a-9fc1-32bf9a16759b': {
    id: '9240604b-ceeb-4d4a-9fc1-32bf9a16759b',
    value: 4.5,
    label: 'Coffee',
    category: 'FOOD',
    frequency: 'DAILY',
    sequence: 0,
  },
}

export { exampleCoffeeExpenses }
