/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = (props) => {
  const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="path-1-inside-1" fill="white">
        <path d="M3 13.5681L11 5.56801L12.1538 4.41422C12.9348 3.63317 14.2012 3.63316 14.9822 4.41421L15.7364 5.16842C16.5175 5.94946 16.5175 7.2158 15.7364 7.99685L14.5826 9.15065L6.55242 17.1809L3.032 17.1657L3 13.5681Z" />
      </mask>
      <path
        d="M14.5826 9.15065L14.0523 9.68098L14.0542 9.68287L14.5826 9.15065ZM15.1111 8.61848L15.1111 8.61842L14.0542 9.68287L14.0543 9.68294L15.1111 8.61848ZM15.113 8.62032L11.5303 5.03768L10.4697 6.09834L14.0523 9.68098L15.113 8.62032ZM6.02209 16.6505L6.01371 16.6589L7.07437 17.7196L7.08275 17.7112L6.02209 16.6505ZM14.5826 9.15065L13.522 8.08998L14.5826 9.15065ZM11 5.56801L12.0607 6.62867L11 5.56801ZM3 13.5681L1.93934 12.5074L1.49446 12.9523L1.50006 13.5814L3 13.5681ZM12.1538 4.41422L11.0931 3.35356V3.35356L12.1538 4.41422ZM15.7364 5.16842L16.7971 4.10775L15.7364 5.16842ZM14.9822 4.41421L16.0429 3.35355V3.35355L14.9822 4.41421ZM3.032 17.1657L1.53206 17.1791L1.54523 18.6593L3.02555 18.6657L3.032 17.1657ZM15.7364 7.99685L16.7971 9.05751V9.05751L15.7364 7.99685ZM6.55242 17.1809L6.54597 18.6808L7.17106 18.6835L7.61308 18.2415L6.55242 17.1809ZM4.06066 14.6287L12.0607 6.62867L9.93934 4.50736L1.93934 12.5074L4.06066 14.6287ZM12.0607 6.62867L13.2145 5.47488L11.0931 3.35356L9.93934 4.50736L12.0607 6.62867ZM16.7971 4.10775L16.0429 3.35355L13.9216 5.47487L14.6758 6.22908L16.7971 4.10775ZM1.50006 13.5814L1.53206 17.1791L4.53194 17.1524L4.49994 13.5547L1.50006 13.5814ZM14.6758 6.93619L13.522 8.08998L15.6433 10.2113L16.7971 9.05751L14.6758 6.93619ZM13.522 8.08998L5.49176 16.1202L7.61308 18.2415L15.6433 10.2113L13.522 8.08998ZM3.02555 18.6657L6.54597 18.6808L6.55887 15.6809L3.03845 15.6657L3.02555 18.6657ZM14.6758 6.22908C14.871 6.42434 14.871 6.74093 14.6758 6.93619L16.7971 9.05751C18.1639 7.69067 18.1639 5.47459 16.7971 4.10775L14.6758 6.22908ZM13.2145 5.47488C13.4097 5.27961 13.7263 5.27961 13.9216 5.47487L16.0429 3.35355C14.676 1.98672 12.46 1.98672 11.0931 3.35356L13.2145 5.47488Z"
        fill={fill}
        mask="url(#path-1-inside-1)"
      />
    </svg>
  );
};

export default IconPath;
