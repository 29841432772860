import React from 'react';
import { Icon } from '@common/Icon';

import { ControlsGroup, ControlsButton } from './Controls.styled';

const Controls = ({ callback }) => {
  const CONTROLS = {
    CREATE: 'CREATE',
  };

  const onCallback = (value) => {
    callback(value);
  };

  return (
    <ControlsGroup>
      <ControlsButton onClick={() => onCallback(CONTROLS.CREATE)}>
        <Icon rotate={45} name="CLOSE" stroke="white" size={20} />
      </ControlsButton>
    </ControlsGroup>
  );
};

Controls.defaultProps = {
  callback: () => {},
};

export { Controls };
