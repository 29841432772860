import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import {
  ToggleWrapper,
  ToggleLabel,
  ToggleTitle,
  ToggleCheckbox,
} from './Toggle.styled';

const Toggle = ({ isActive, onChange, title, config }) => {
  const layoutStyle = prop('layout')(config);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    onChange(isChecked);
  };
  return (
    <ToggleWrapper style={layoutStyle}>
      {title && <ToggleTitle>{title}</ToggleTitle>}
      <ToggleCheckbox
        checked={isActive}
        onChange={(e) => handleChange(e)}
        id="checkbox"
        type="checkbox"
      />
      <ToggleLabel className="ToggleLabel" htmlFor="checkbox" />
    </ToggleWrapper>
  );
};

Toggle.defaultProps = {
  isActive: false,
  onChange: () => {},
  title: '',
  config: {},
};

Toggle.propTypes = {
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  config: PropTypes.object,
};

export { Toggle };
