/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = (props) => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M11.1225 18H10.6076C8.96185 18 7.42498 17.1775 6.51209 15.8082L3.50003 11.2901C3.20914 10.8537 3.32704 10.2642 3.76338 9.97329C4.16715 9.70412 4.70889 9.78269 5.01955 10.1555L6.97332 12.5L6.97332 5C6.97332 4.44772 7.42104 4 7.97332 4C8.5256 4 8.97332 4.44772 8.97332 5V8.5V3.5C8.97332 2.94772 9.42104 2.5 9.97332 2.5C10.5256 2.5 10.9733 2.94772 10.9733 3.5V9.5V5.5C10.9733 4.94772 11.421 4.5 11.9733 4.5C12.5256 4.5 12.9733 4.94772 12.9733 5.5L12.9733 10.9733L12.9733 7.5C12.9733 6.94772 13.421 6.5 13.9733 6.5C14.5256 6.5 14.9733 6.94772 14.9733 7.5L14.9733 14.1492C14.9733 15.0236 14.6757 15.872 14.1295 16.5548C13.3987 17.4682 12.2923 18 11.1225 18Z"
        stroke={stroke}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
