import { prop, values, omit } from 'ramda';

const isEmpty = (arr) => !arr || arr.length <= 0;

export const createSearchables = (items) => {
  if (isEmpty(items)) return [];
  const result = items.reduce((prev, curr) => {
    // const omitted = omit(['id'])(curr);
    const filterIt = values(curr).filter(Boolean);
    const searchMeta = filterIt.map((item) => item.toString());
    const newItem = { ...curr, searchMeta };
    return [...prev, newItem];
  }, []);
  return result;
};
